<template>
  <div>
    <div class="columns is-centered">
      <div class="column is-half">
        <!-- Open Proposals -->
        <div class="mb-lg">
          <p class="has-text-weight-bold has-text-centered mb-md mt-md">Open Proposals</p>
          <!-- Drafts -->
          <PresetLink
            :loading="loading"
            :count="('draft' in statuses) ? parseInt(statuses.draft.count) : 0"
            :alert="false"
            label="Draft"
            preset="draft"
            description="Proposals that have not yet been submitted."
          />
          <!-- Proposed -->
          <PresetLink
            :loading="loading"
            :count="('proposed' in statuses) ? statuses.proposed.count : 0"
            :alert="context.isMerchant()"
            label="Sent to Merchant"
            preset="proposed"
            description="Proposals that are awaiting approval."
          />
          <!-- Revised -->
          <PresetLink
            :loading="loading"
            :count="('revised' in statuses) ? statuses.revised.count : 0"
            :alert="context.isSupplier()"
            label="Returned by Merchant"
            preset="revised"
            description="Proposals that have been returned and require your attention."
          />
          <!-- Approved -->
          <PresetLink
            v-if="totalApproved"
            :loading="loading"
            :count="totalApproved"
            :alert="false"
            label="Approved"
            preset="approvedSupplier"
            description="Proposals that are approved and preparing to be published."
          />
          <!-- ReadyToPublish (completed, but not acknowledged) -->
          <ReadyToPublish />
        </div>
        <!-- Closed Proposals -->
        <div class="mt-lg">
          <p class="has-text-weight-bold has-text-centered mb-md">Closed Proposals</p>
          <!-- Completed -->
          <PresetLink
            :loading="loading"
            :count="totalCompleted"
            :alert="false"
            label="Completed"
            preset="completedSupplier"
            description="Proposals that are completed."
          />
          <!-- Declined -->
          <div v-if="totalDeclined" class="mt-md">
            <p class="has-text-centered">
              <router-link class="has-text-grey-light" :to="{ name: 'proposals.list', query: { preset: 'declined' } }">
                Declined ({{ totalDeclined }})
              </router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PresetLink from './PresetLink'
import ReadyToPublish from './ReadyToPublish'

export default {
  components: {
    PresetLink,
    ReadyToPublish
  },
  computed: {
    ...mapGetters(['context']),
    totalApproved () {
      let count = 0
      if ('approved' in this.statuses) {
        count += this.statuses.approved.count
      }
      if ('pricing_approved' in this.statuses) {
        count += this.statuses.pricing_approved.count
      }
      return count
    },
    totalDeclined () {
      return ('declined' in this.statuses) ? this.statuses.declined.count : 0
    },
    totalCompleted () {
      let count = 0
      if ('completed_acknowledged' in this.statuses) {
        count += this.statuses.completed_acknowledged.count
      }
      if ('completed_unacknowledged' in this.statuses) {
        count += this.statuses.completed_unacknowledged.count
      }
      return count
    }
  },
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    statuses: {
      type: Object,
      required: true
    },
    productProposalsCount: {
      type: Number,
      required: true,
      default: 0
    }
  }
}
</script>
