<template>
<div>
  <div v-if="!loadingFeed && feedDetail !== null">
    <!-- Intro -->
    <div class="mb-lg">
      <p class="has-text-weight-bold has-text-info has-text-centered mb-md">Step 2 of 2: Review Import</p>
    </div>
    <div class="mb-lg">
      <div class="has-background-light rounded-lg pa-md">
        <Progress :feed="feedDetail" size="is-large" :emitRefresh="true" @refresh="loadFeed(true)" />
      </div>
    </div>
    <!-- Proposal Details -->
    <div v-if="!loadingProposal && proposalDetail !== null && feedDetail.status === 'success'">
      <!-- Item Counters -->
      <div class="mb-lg">
        <!-- Items -->
        <div class="mb-sm">
          <div class="columns">
            <div class="column is-half">
              <div class="has-background-light rounded-lg pa-md has-text-centered">
                <p class="is-size-4 has-text-weight-bold">
                  <span v-if="activeProposalItems > 0" class="has-text-success">{{ activeProposalItems }}</span>
                  <span v-else class="has-text-grey-light">0</span>
                </p>
                <p class="has-text-grey is-size-7"># Items Imported</p>
              </div>
            </div>
            <div class="column is-half">
              <div class="has-background-light rounded-lg pa-md has-text-centered">
                <p class="is-size-4 has-text-weight-bold">
                  <span v-if="skippedItems > 0" class="has-text-warning">{{ skippedItems }}</span>
                  <span v-else class="has-text-success">0</span>
                </p>
                <p class="has-text-grey is-size-7"># Items Skipped</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Warning -->
      <div class="mt-lg" v-if="skippedItems > 0">
        <div v-if="activeProposalItems <= 0 && skippedItems > 0">
          <b-message class="is-size-7" type="is-warning">
            Items are skipped from your proposal if they do not exist, <span v-if="proposalDetail.proposal_type === 'new_product'">have previously been approved,</span> or are already on another
            proposal awaiting approval from {{ proposalDetail.connection.retailer.name }}. Unfortunately, all of the items you submitted
            met one or more of these conditions. Please archive this proposal.
          </b-message>
        </div>
        <div v-else>
          <b-message class="is-size-7" type="is-warning">
            Items are skipped for your proposal if they do not exist, <span v-if="proposalDetail.proposal_type === 'new_product'">have previously been approved,</span> or are already on another
            proposal awaiting approval from {{ proposalDetail.connection.retailer.name }}.
          </b-message>
        </div>
      </div>
      <!-- Show details -->
      <div class="mt-sm has-text-right">
        <a href="#" class="has-text-info is-size-7" @click="showDetails = !showDetails" v-text="(showDetails) ? 'Hide Details' : 'Show Details'"></a>
      </div>
      <!-- Feed Details -->
      <div class="mb-lg">
        <div v-if="showDetails" class="mt-md">
          <ImportSummary :feedDetail={...feedDetail} />
        </div>
      </div>
      <!-- Navigate -->
      <div class="mt-xl">
        <div class="columns is-centered">
          <div class="column">
            <b-button
              type="is-light rounded-lg"
              size="is-medium"
              @click="goBack"
              expanded
              ><b-icon icon="arrow-left"></b-icon>
            </b-button>
          </div>
          <div v-if="activeProposalItems <= 0 && proposalDetail.item_rejected_count > 0" class="column">
            <b-button
              type="is-primary rounded-lg"
              size="is-medium"
              expanded
              :disabled="saving"
              :loading="saving"
              @click="archiveProposal"
            >Archive Proposal</b-button>
          </div>
          <div v-else class="column">
            <b-button
              type="is-primary rounded-lg"
              size="is-medium"
              expanded
              :disabled="saving"
              :loading="saving"
              @click="submitProposal"
            >Submit</b-button>
          </div>
        </div>
      </div>
    </div>
    <!-- Feedback -->
    <div class="mb-md">
      <b-message v-if="feedDetail.status === 'success'" type="is-success" class="is-size-7">
        Your file has been imported. Click 'View Proposed Products' below to
        view your current list of proposed products. To expedite your product approval, please review those products
        for compatibility errors.
      </b-message>
      <b-message v-else-if="feedDetail.status === 'failure'" type="is-warning" class="is-size-7">
        There was an error processing your file -- most the time, these errors are related your file format or column headers.
        Please click <a href="#" class="has-text-info has-text-weight-bold" @click.prevent="inspectFeed = true">here</a> to review your import. If you need additional assistance,
        please contact {{ app.supportEmail }}.
      </b-message>
      <b-message v-else class="is-size-7">
        We're currently processing your feed. Depending on the size of your file, this may take a few minutes. You can safely
        leave this page to complete other tasks.
      </b-message>
    </div>
    <div class="has-text-centered">
      <b-button type="is-info is-light rounded-md" @click="goToProposals()">View Proposed Products</b-button>
    </div>
  </div>
  <div v-else>
    <loading message="Waiting on import details..." />
  </div>
  <!-- Inspection Modal -->
  <b-modal
    has-modal-card
    :active.sync="inspectFeed"
    :can-cancel="['escape', 'x']"
    ><FeedQueueModal :selectedFeed="feedDetail" />
  </b-modal>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Progress from '@/components/Models/FeedQueue/Presenters/Progress'
import ImportSummary from '@/components/Models/FeedQueue/Presenters/ImportSummary'
import FeedQueueModal from '@/components/Modals/FeedQueue/FeedQueueModal'

export default {
  components: {
    Progress,
    FeedQueueModal,
    ImportSummary
  },
  computed: {
    ...mapGetters(['app', 'context']),
    skippedItems () {
      return this.proposalDetail.item_rejected_count + this.proposalDetail.item_skipped_count
    },
    activeProposalItems () {
      if (this.proposalDetail) {
        return this.proposalDetail.item_count - this.proposalDetail.item_rejected_count
      }
      return 0
    },
    statusPresenter () {
      const status = this.feedDetail.processed_at !== null && this.$utils.find(this.feedDetail.jobs[0].metadata, ['name', 'status'])
      switch (status.value) {
        case 'Successful':
          return { class: 'has-text-success', label: status.value }
        default:
          return { class: 'has-text-warning', label: status.value }
      }
    }
  },
  data () {
    return {
      loadingFeed: true,
      loadingProposal: true,
      loadingCompatibility: true,
      feedDetail: null,
      proposalDetail: null,
      rulesets: [],
      showDetails: false,
      inspectFeed: false,
      saving: false,
      errors: {}
    }
  },
  methods: {
    goBack () {
      this.$emit('navigate', 'Build')
    },
    toggleDetails () {
      this.showDetails = !this.showDetails
    },
    loadProposal (silent = false) {
      if (!silent) this.loadingProposal = true
      const endpoint = this.context.route + '/proposals/' + this.proposal.id + '/'
      this.$http.get(endpoint).then(response => {
        this.proposalDetail = response.data
        this.loadingProposal = false
      })
    },
    loadFeed (silent = false) {
      if (!silent) this.loadingFeed = true
      const endpoint = this.context.route + '/feed-queue/' + this.feed.id + '/'
      this.$http.get(endpoint).then(response => {
        this.feedDetail = response.data
        this.$emit('feedDetails:queued', response.data)
        if (this.feedDetail.status === 'success' || this.feedDetail.status === 'failure') {
          // this.loadProposal(true)
        }
        this.loadingFeed = false
      })
    },
    submitProposal () {
      // this.saving = true
      // const endpoint = this.context.route + '/proposals/' + this.proposal.id + '/'
      // const body = {
      //   status: 'proposed'
      // }
      // this.$http.patch(endpoint, body).then(response => {
      //   this.$buefy.toast.open({ type: 'is-success', message: 'Proposal submitted!' })
      //   this.$router.push({ name: 'proposals.dashboard' })
      // })
      this.$router.push({ name: 'product.proposals.list' })
    },
    archiveProposal () {
      this.saving = true
      const endpoint = this.context.route + '/proposals/' + this.proposal.id + '/'
      const body = {
        status: 'archived'
      }
      this.$http.patch(endpoint, body).then(response => {
        this.$buefy.toast.open({ type: 'is-info', message: 'Proposal archived!' })
        this.$router.push({ name: 'catalog' })
      })
    },
    getJobMetadataFromKey (row, key, onlyValue) {
      let job = null
      if (row.length && row[0].metadata.length) {
        job = row[0].metadata.find(el => el.name === key)
      }
      if (job && job.value && onlyValue) {
        return job.value
      } else {
        return job !== null && job !== undefined
      }
    },
    goToProposals () {
      this.$router.push({ name: 'merchandise.proposals' })
    }
  },
  mounted () {
    this.loadFeed()
  },
  props: {
    feed: {
      type: Object,
      required: true
    }
  }
}
</script>
