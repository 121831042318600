<template>
  <div>
    <div class="modal-card" style="width: auto; max-width: 900px;">
      <section class="modal-card-body">
        <div v-if="!loading">
          <div class="mb-md">
            <p class="is-size-5 has-text-black">{{ product.parent_name }}</p>
            <p class="has-text-weight-bold has-text-grey mb-sm">Product #{{ product.parent_identifier }}</p>
            <div class="tags">
              <StatusPresenter :proposal="proposal" />
              <CompatibilityPresenter :proposal="proposal" />
            </div>
          </div>
          <!-- Media -->
          <div class="mb-md">
            <Media :proposal="proposal" :product="product" />
          </div>
          <!-- Attribute Reader -->
          <div class="mb-md">
            <AttributesReader :proposal="proposal" :product="product" />
          </div>
        </div>
        <!-- Loading State -->
        <div v-else class="py-lg has-text-centered" style="min-width:600px; max-width:800px">
          <div class="columns is-centered">
            <div class="column is-half">
              <p class="has-text-weight-bold has-text-grey-light mb-md">{{ loadingStage }}</p>
              <b-progress type="is-info" size="is-small" :value="loadingProgress"></b-progress>
            </div>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot space-between has-background-white" >
        <b-button @click="$parent.close()">Close</b-button>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CdnTransform from '@/mixins/CdnTransform'
import StatusPresenter from '@/components/Models/Proposal/Presenters/StatusTag'
import CompatibilityPresenter from '@/components/Models/Proposal/Presenters/CompatibilityTag'
import AttributesReader from './AttributesReader'
import Media from './Media'

export default {
  components: {
    CdnTransform,
    StatusPresenter,
    CompatibilityPresenter,
    AttributesReader,
    Media
  },
  computed: {
    ...mapGetters(['context']),
    loading () {
      return Boolean(this.loadingProposal || this.loadingConnection || this.loadingProduct)
    },
    loadingStage () {
      if (this.loadingProposal) return 'Loading proposal...'
      if (this.loadingConnection) return 'Loading connection...'
      if (this.loadingProduct) return 'Loading product...'
      return 'Done!'
    },
    loadingProgress () {
      let progress = 0
      if (!this.loadingProposal && this.proposal) progress += 25
      if (!this.loadingConnection && this.connection) progress += 25
      if (!this.loadingProduct && this.product) progress += 25
      return progress
    }
  },
  data () {
    return {
      loadingProposal: false,
      loadingConnection: true,
      loadingProduct: true,
      connection: null,
      product: null,
      activeTab: 0,
      saving: false,
      errors: {}
    }
  },
  methods: {
    save () {
      const endpoint = this.context.route + '/proposals/' + this.proposal.id + '/'
      const body = {
        status: 'archived'
      }
      this.$http.patch(endpoint, body).then(response => {
        this.$buefy.toast.open({ type: 'is-info', message: 'Proposal Archived!' })
        this.$emit('proposal:archived')
        this.$parent.close()
      }).finally(() => {
        this.saving = false
      })
    },
    loadConnection (quiet = false) {
      if (!this.proposal) return null
      if (!quiet) this.loadingConnection = true
      const endpoint = this.context.route + '/connections/' + this.proposal.connection.id + '/'
      this.$http.get(endpoint).then(response => {
        this.connection = response.data
        this.loadingConnection = false
      })
    },
    loadProduct (quiet = false, id) {
      if (!quiet) this.loadingProduct = true
      const endpoint = this.context.route + '/proposals/' + this.proposal.id + '/products/' + id + '/'
      this.$http.get(endpoint).then(response => {
        this.product = response.data
        this.loadingProduct = false
      })
    }
  },
  mixins: [CdnTransform],
  mounted () {
    this.loadProduct(false, this.proposal.products[0].id)
    this.loadConnection()
  },
  props: {
    proposal: {
      type: Object
    }
  }
}
</script>
