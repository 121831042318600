<template>
<div>
  <div class="has-background-white rounded-lg">
    <div class="columns is-vcentered">
      <div class="column px-lg">
        <b-button
          type="is-primary rounded-lg"
          class="is-pulled-right px-md"
          :loading="loading"
          @click="showCreateModal=true"
        >Create
        </b-button>
      </div>
    </div>
    <div class="border-t" />
    <!-- Results -->
    <div>
      <b-table
        :data="results"
        :mobile-cards="false"
        :loading="loading"
        hoverable
        class="is-size-7">
        <template>
          <!-- ID # -->
          <b-table-column
            label="ID"
            cell-class="has-text-grey-light"
            width="64"
            centered
            v-slot="props">
            {{ props.row.id }}
          </b-table-column>
          <!-- Name -->
          <b-table-column
            label="Name"
            header-class="nowrap"
            cell-class="nowrap"
            v-slot="props">
            <a href="#"
              class="has-text-weight-bold has-text-info"
              @click.prevent="onEdit(props.row)"
            >{{ props.row.name }}</a>
          </b-table-column>
          <!-- Position -->
          <b-table-column
            label="Position"
            cell-class="nowrap"
            width="128"
            centered
            v-slot="props">
            {{ props.row.position }}
          </b-table-column>
          <!-- Target Column -->
          <b-table-column
            label="Target"
            cell-class="nowrap"
            width="128"
            centered
            v-slot="props">
            <span v-if="props.row.target" >{{ props.row.target.charAt(0).toUpperCase() + props.row.target.slice(1) }}</span>
            <span v-else>--</span>
          </b-table-column>
          <!-- Is Required? -->
          <b-table-column
            label="Is Required?"
            header-class="nowrap"
            cell-class="nowrap"
            width="128"
            centered
            v-slot="props">
            <p>
              <span v-if="props.row.is_required" class="has-text-warning">Yes</span>
              <span v-else class="has-text-grey-light">No</span>
            </p>
          </b-table-column>
          <!-- Last Updated -->
          <b-table-column
            label="Updated At"
            width="128"
            centered
            v-slot="props">
            {{ $dates.utc(props.row.updated_at).local().format('MMM DD, YYYY') }}
          </b-table-column>
          <!-- Rule Validators -->
          <b-table-column
            label="Validators"
            width="128"
            centered
            v-slot="props">
            <a href="#"
              class="has-text-weight-bold has-text-info"
              @click.prevent="onValidator(props.row)"
            >
              <span v-if="!props.row.rule_validators.length">Add</span>
              <span v-else>Edit</span>
          </a>
          </b-table-column>
        </template>
        <template #empty>
          <div class="py-xxl has-text-grey has-text-centered">
            {{ (loading) ? "Loading data..." : 'Rules are not yet added to this rule group!' }}
          </div>
        </template>
      </b-table>
    </div>
  </div>
  <div class="mt-md">
    <b-pagination
      :total="total"
      :current.sync="currentPage"
      :rounded="true"
      :per-page="perPage"
      @change="paginate">
    </b-pagination>
  </div>
  <!-- Create rule -->
  <b-modal
    v-if="showCreateModal"
    has-modal-card
    :active="showCreateModal"
    :can-cancel="['escape', 'x']"
    @close="showCreateModal=false"
    ><CreateRule @reload:rules="reload" />
  </b-modal>
  <!-- Update rule -->
  <b-modal
    v-if="showUpdateModal"
    has-modal-card
    :active="showUpdateModal"
    :can-cancel="['escape', 'x']"
    @close="showUpdateModal=false"
    ><UpdateRule @reload:rules="reload" :rule="inspectRule"/>
  </b-modal>
  <!-- Rule Validator -->
  <b-modal
    v-if="showValidatorModal"
    has-modal-card
    :active="showValidatorModal"
    :can-cancel="['escape', 'x']"
    @close="showValidatorModal=false"
    ><RuleValidator @reload:rules="reload" :rule="inspectRule"/>
  </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Filterable from '@/mixins/Filterable'
import CreateRule from '@/components/Models/Rule/Modals/Create'
import UpdateRule from '@/components/Models/Rule/Modals/Update'
import RuleValidator from '@/components/Models/Rule/Modals/Validator'

export default {
  components: {
    CreateRule,
    UpdateRule,
    RuleValidator
  },
  computed: {
    ...mapGetters(['api', 'context']),
    endpoint () {
      return this.context.route + `/compatibility/rulesets/${this.$route.params.ruleset}/rules/`
    },
    alwaysAttach () {
      return {
        rule_group_id: this.$route.params.id,
        order_by: this.position
      }
    }
  },
  data: () => {
    return {
      key: 'rules',
      loading: true,
      orderBy: 'position',
      showCreateModal: false,
      showUpdateModal: false,
      showValidatorModal: false,
      inspectRule: null
    }
  },
  methods: {
    reload () {
      this.load()
    },
    onEdit (rule) {
      this.inspectRule = rule
      this.showUpdateModal = true
    },
    onValidator (rule) {
      this.inspectRule = rule
      this.showValidatorModal = true
    }
  },
  mixins: [Filterable],
  mounted () {
    this.load()
  },
  props: {
  }
}
</script>
