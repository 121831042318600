<template>
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="mb-lg">
        <div class="columns is-vcentered">
          <div class="column is-one-third">
            <breadcrumbs :history="breadcrumbs.history" :current="breadcrumbs.current" />
          </div>
          <div class="column is-one-third">
            <h1 class="has-text-centered is-size-4">Proposed Products</h1>
          </div>
        </div>
      </div>
      <!-- Filters Toolbar -->
      <div class="has-background-white rounded-lg pa-md mb-xxs">
        <div class="columns">
          <div class="column">
            <ProductProposalsFilters :applied-filters="filters" @filter="filter" />
          </div>
          <div class="column is-narrow">
            <b-select v-model="orderBy" size="is-medium" expanded @input="sort">
              <option value="-id">Most Recent</option>
              <option value="id">Oldest</option>
            </b-select>
          </div>
        </div>
      </div>
      <!-- Actions Toolbar -->
      <div class="has-background-white rounded-lg px-md py-sm mb-xxs">
        <div class="columns is-vcentered is-mobile">
          <div class="column is-narrow">
            <b-dropdown :disabled="loading || selected.length === 0">
              <button class="button" slot="trigger" slot-scope="{ active }" :disabled="selected.length === 0">
                <span>More Actions</span>
                <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
              </button>
              <template v-if="context.isSupplier()">
                <b-dropdown-item has-link><a href="#" @click.prevent="showWorkflowActionsModal = true">Archive Proposal</a></b-dropdown-item>
              </template>
              <template v-if="context.isMerchant()">
                <b-dropdown-item has-link><a href="#" @click.prevent="showApplyTagsModal = true">Add Tags</a></b-dropdown-item>
                <!-- <b-dropdown-item has-link><a href="#" @click.prevent="showMergeProductsModal = true">Merge Products into Proposal</a></b-dropdown-item> -->
              </template>
            </b-dropdown>
          </div>
          <div class="column">
            <div class="has-text-right is-size-7">
              <!-- Counts -->
              <p v-if="loading">Loading...</p>
              <p v-else>{{ total }} {{ (total === 1) ? 'proposal' : 'proposals' }} found.</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Results -->
      <div>
        <div class="mb-md">
          <b-table
            :data="results"
            :checked-rows.sync="selected"
            :mobile-cards="false"
            :loading="loading"
            hoverable
            checkable
            checkbox-position="left">
            <template>
              <!-- Image -->
              <b-table-column
                :visible="true"
                label=""
                cell-class="nowrap"
                width="128"
                v-slot="props">
                <div v-if="props.row.products[0].primary_media">
                  <p><img class="rounded-lg" :src="props.row.products[0].primary_media.url | cloudinary(120,120,'fill')" width="120" height="120"/></p>
                </div>
                <div v-else-if="props.row.products[0].media.length">
                  <p><img class="rounded-lg" :src="props.row.products[0].media[0].url | cloudinary(120,120,'fill')" width="120" height="120"/></p>
                </div>
                <div v-else>
                  <p><img class="rounded-lg" src="https://placehold.co/120x120@2x/F1F1F1/F1F1F1.png" width="120" height="120"/></p>
                </div>
              </b-table-column>
              <!-- Name -->
              <b-table-column
                :visible="true"
                label="Product"
                header-class="is-size-8"
                v-slot="props">
                <router-link :to="{ name: 'merchandise.proposal.attributes', params: { proposal: props.row.id } }" class="has-text-info has-text-weight-bold is-size-7">{{ props.row.products[0].name }}</router-link>
                <p v-if="context.isMerchant()" class="is-size-8">{{ props.row.connection.brand.name }}</p>
                <p class="is-size-8">{{ (props.row.department) ? props.row.department.name : 'Unassigned' }}</p>
                <div class="mb-sm">
                  <CostPricePresenter :product="props.row.products[0]" />
                </div>
                <div v-if="props.row.tags.length" class="mb-sm">
                  <ProposalTagPresenter :proposal="props.row" />
                </div>
                <div class="is-size-9 is-italic has-text-grey-light">
                  <p>{{ $dates.utc(props.row.proposed_at).local().format('MMM D, YYYY @h:mm A') }}</p>
                </div>
              </b-table-column>
              <!-- Merchant -->
              <b-table-column
                v-if="context.isSupplier()"
                label="Shared With"
                header-class="is-size-8"
                cell-class="nowrap is-size-7"
                width="200"
                v-slot="props">
                {{ props.row.connection.retailer.name || '--' }}
              </b-table-column>
              <!-- Owner -->
              <b-table-column
                :visible="context.isMerchant()"
                label="Owner"
                header-class="is-size-8"
                cell-class="nowrap"
                centered
                width="98"
                v-slot="props">
                <div class="has-background-info-light has-text-info has-text-white rounded-full has-text-centered has-text-weight-bold is-size-7 is-inline-block" style="width:48px; height:48px; padding-top:12px">
                  {{ props.row.recipient.user.first_name.substring(0,1) }}{{ props.row.recipient.user.last_name.substring(0,1) }}
                </div>
              </b-table-column>
              <!-- Compatibility -->
              <b-table-column
                label="Compatibility"
                header-class="is-size-8"
                cell-class="nowrap"
                centered
                width="98"
                v-slot="props">
                <CompatibilityPresenter :proposal="props.row" />
              </b-table-column>
              <!-- Status -->
              <b-table-column
                label="Status"
                header-class="is-size-8"
                cell-class="nowrap is-size-7"
                width="98"
                v-slot="props"
                centered>
                <StatusPresenter :proposal="props.row" />
              </b-table-column>
            </template>
            <template #empty>
              <div class="py-xxl has-text-grey has-text-centered">
                {{ (loading) ? "Loading data..." : 'No proposals found!' }}
              </div>
            </template>
          </b-table>
        </div>
        <b-pagination
          :total="total"
          :current.sync="currentPage"
          :rounded="true"
          :per-page="perPage"
          @change="paginate">
        </b-pagination>
      </div>
      <!-- Quicklook Modal -->
      <b-modal
        has-modal-card
        :active.sync="showQuicklook"
        :can-cancel="['escape', 'x']"
        @close="focusProposal = null"
        :width="1200"
        ><QuicklookModal :proposal="focusProposal" />
      </b-modal>
      <!-- Merge Products Modal -->
      <b-modal
        has-modal-card
        :active.sync="showApplyTagsModal"
        :can-cancel="['escape', 'x']"
        ><ApplyTagsModal :proposals="selected" @tags:saved="load" />
      </b-modal>
      <!-- Merge Products Modal -->
      <b-modal
        has-modal-card
        :active.sync="showMergeProductsModal"
        :can-cancel="['escape', 'x']"
        ><MergeProductsModal :proposals="selected" />
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Filterable from '@/mixins/Filterable'
import ProductProposalsFilters from '../components/Proposals/ProposalFilters'
import StatusPresenter from '@/components/Models/Proposal/Presenters/StatusTag'
import ProposalTagPresenter from '@/components/Models/Proposal/Presenters/ProposalTag'
import CompatibilityPresenter from '@/components/Models/Proposal/Presenters/CompatibilityTag'
import CostPricePresenter from '@/components/Models/Product/Presenters/CostPrice'
import QuicklookModal from '../components/Proposals/Quicklook'
import MergeProductsModal from '../components/Proposals/MergeProductsModal'
import ApplyTagsModal from '../components/Proposals/ApplyTagsModal'

export default {
  components: {
    ProductProposalsFilters,
    StatusPresenter,
    CompatibilityPresenter,
    ProposalTagPresenter,
    CostPricePresenter,
    QuicklookModal,
    MergeProductsModal,
    ApplyTagsModal
  },
  computed: {
    ...mapGetters(['context']),
    breadcrumbs () {
      const crumbs = {
        current: 'Merchandise',
        history: [
          { label: 'Catalog', name: 'catalog.index' }
        ]
      }
      if (this.context.isSupplier()) {
        crumbs.history.push({ label: 'Merchandise', name: 'catalog.merchandise' })
      }
      return crumbs
    },
    alwaysAttach () {
      const attach = {
        short: 1,
        is_product_proposal: 1
      }
      if ('connection' in this.$route.query) {
        attach.connection_id = this.$route.query
      }
      return attach
    },
    endpoint () {
      return this.context.route + '/proposals/'
    }
  },
  data () {
    return {
      key: 'product-proposals',
      showMergeProductsModal: false,
      showApplyTagsModal: false,
      showQuicklook: false,
      focusProposal: null
    }
  },
  methods: {
    reload () {
      this.selected = []
      this.load()
    },
    quicklook (proposal) {
      this.focusProposal = proposal
      this.showQuicklook = true
    }
  },
  mounted () {
    this.load()
  },
  mixins: [Filterable]
}
</script>
