import Index from './views/Index'
import Products from './views/Products'
import Product from './views/Product'
import Item from './views/Item'
import Items from './views/Items'
import ImportHistory from './views/ImportHistory'
import ImportPermits from './views/ImportPermits'
import ImportProducts from './views/ImportProducts'
import ImportProductsLegacy from './views/ImportProductsLegacy'
import ImportInventory from './views/ImportInventory'
import ImportInventoryLegacy from './views/ImportInventoryLegacy'
import ImportShopify from './views/ImportShopify'
import Inventory from './views/Inventory'
import InventorySummary from './views/InventorySummary'
import Offers from './views/Offers'
import ImportRequest from './views/ImportRequest'
import ImportRequests from './views/ImportRequests'
import CreateImportRequest from './views/CreateImportRequest'
import Catalog from './views/Catalog'
import Dashboard from './views/Dashboard'
import Import from './views/Import'
import Browse from './views/Browse'
import Submit from './views/Submit'
import SubmitNew from './views/SubmitNew'
import SubmitUpdates from './views/SubmitUpdates'

export default [{
  path: '/catalog/index',
  name: 'catalog.index',
  component: Index
}, {
  path: '/catalog',
  component: Catalog,
  children: [{
    path: 'import',
    name: 'catalog.import',
    component: Import
  }, {
    path: 'browse',
    name: 'catalog.browse',
    component: Browse
  }, {
    path: 'submit',
    name: 'catalog.submit',
    component: Submit
  }, {
    path: '',
    name: 'catalog',
    component: Dashboard
  }]
}, {
  path: '/catalog/products',
  name: 'catalog.products',
  component: Products
}, {
  path: '/catalog/products/:id',
  name: 'catalog.product',
  component: Product
}, {
  path: '/catalog/products/:productId/:variantId',
  name: 'catalog.product.item',
  component: Item
}, {
  path: '/catalog/items/:variantId',
  name: 'catalog.item',
  component: Item
}, {
  path: '/catalog/inventory-summary',
  name: 'catalog.inventory.summary',
  component: InventorySummary
}, {
  path: '/catalog/inventory',
  name: 'catalog.inventory',
  component: Inventory
}, {
  path: '/catalog/items',
  name: 'catalog.items',
  component: Items
}, {
  path: '/catalog/offers',
  name: 'catalog.offers',
  component: Offers
}, {
  path: '/catalog/import-products',
  name: 'catalog.import.products',
  component: ImportProducts
}, {
  path: '/catalog/import-products-data',
  name: 'catalog.import.products.data',
  component: ImportProductsLegacy
}, {
  path: '/catalog/import-inventory',
  name: 'catalog.import.inventory',
  component: ImportInventory
}, {
  path: '/catalog/import-inventory-data',
  name: 'catalog.import.inventory.data',
  component: ImportInventoryLegacy
}, {
  path: '/catalog/import-offers',
  name: 'catalog.import.permits',
  component: ImportPermits
}, {
  path: '/catalog/import-shopify',
  name: 'catalog.import.shopify',
  component: ImportShopify
}, {
  path: '/catalog/import-history',
  name: 'catalog.import.history',
  component: ImportHistory
}, {
  path: '/catalog/feed-requests/create',
  name: 'catalog.import.request.create',
  component: CreateImportRequest
}, {
  path: '/catalog/feed-requests',
  name: 'catalog.import.requests',
  component: ImportRequests
}, {
  path: '/catalog/feed-requests/:id/',
  name: 'catalog.import.request',
  component: ImportRequest
}, {
  path: '/catalog/submit-new-products',
  name: 'catalog.submit.new',
  component: SubmitNew
}, {
  path: '/catalog/submit-updates',
  name: 'catalog.submit.updates',
  component: SubmitUpdates
}]
