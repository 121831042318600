<template>
  <div>
    <!-- Filters Toolbar -->
    <div class="has-background-white rounded-lg pa-md mb-xxs">
      <div class="columns">
        <div class="column">
          <ProposalVariantFilters :applied-filters="filters" @filter="filter" />
        </div>
        <div class="column is-narrow">
          <b-select v-model="orderBy" size="is-medium" expanded @input="sort">
            <option value="-id">Most Recent</option>
            <option value="id">Oldest</option>
          </b-select>
        </div>
      </div>
    </div>
    <!-- Actions Toolbar -->
    <div class="has-background-white rounded-lg px-md py-sm mb-xxs">
      <div class="columns is-vcentered is-mobile">
        <div class="column">
          <b-dropdown :disabled="loading || selected.length === 0">
            <button class="button" slot="trigger" slot-scope="{ active }" :disabled="selected.length === 0">
              <span>Actions</span>
              <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
            </button>
            <template>
              <b-dropdown-item :disabled="context.isSupplier()" @click="showCreateIssueModal = true">Create Issue</b-dropdown-item>
            </template>
            <template>
              <b-dropdown-item :disabled="context.isSupplier()" @click="showAddToOpenIssueModal = true">Add to Open Issue</b-dropdown-item>
            </template>
            <hr class="dropdown-divider"/>
            <template v-if="canRemoveItems">
              <b-dropdown-item has-link><a href="#" @click.prevent="showRemoveItemsModal = true">Remove Items</a></b-dropdown-item>
            </template>
            <template v-else>
              <b-dropdown-item custom><span class="has-text-grey-light">No Actions</span></b-dropdown-item>
            </template>
          </b-dropdown>
        </div>
        <div class="column is-narrow">
          <b-field class="">
            <b-radio-button v-model="tabView" size="is-small" native-value="offers">Pricing</b-radio-button>
            <b-radio-button v-model="tabView" size="is-small" native-value="skus">SKUs</b-radio-button>
            <b-radio-button v-model="tabView" size="is-small" native-value="images">Images</b-radio-button>
            <b-radio-button v-model="tabView" size="is-small" native-value="shipping">Shipping</b-radio-button>
            <b-radio-button v-model="tabView" size="is-small" native-value="compatibility">Compatibility</b-radio-button>
            <b-radio-button v-if="!isProductProposal" v-model="tabView" size="is-small" native-value="issues">Issues</b-radio-button>
          </b-field>
        </div>
        <div class="column">
          <div class="has-text-right is-size-7">
            <!-- Counts -->
            <p v-if="loading">Loading...</p>
            <p v-else>{{ total }} {{ (total === 1) ? 'Item' : 'Items' }}</p>
          </div>
        </div>
        <div class="column is-narrow">
          <div class="">
            <b-button size="is-small" @click="showImages = !showImages" :disabled="showImages" icon-left="format-list-checkbox"></b-button>
            <b-button size="is-small" @click="showImages = !showImages" :disabled="!showImages" icon-left="format-align-justify"></b-button>
          </div>
        </div>
      </div>
    </div>
    <!-- Results -->
    <div>
      <div class="mb-md">
        <b-table
          :data="results"
          :checked-rows.sync="selected"
          :mobile-cards="false"
          :loading="loading"
          hoverable
          checkable
          checkbox-position="left"
          class="is-size-7">
          <template>
            <!-- Image -->
            <b-table-column
              :visible="showImages && columns.includes('name')"
              label=""
              cell-class="nowrap has-text-centered"
              width="64"
              v-slot="props">
              <p v-if="props.row.media[0].media !== 'default'">
                <a :href="props.row.media[0].url|cloudinary" target="_blank">
                  <img class="rounded-md" :src="props.row.media[0].url | cloudinary(64,64,'fill','2.0')" width="64" height="64"/>
                </a>
              </p>
              <p v-else>
                <img class="rounded-md" src="https://res.cloudinary.com/revcascade/image/upload/w_64,h_64/static/placeholder-lg.png" width="64" height="64"/>
              </p>
              <p class='has-text-grey-light  is-size-8'>{{ props.row.media[0].width }} x {{ props.row.media[0].height }}</p>
            </b-table-column>
            <!-- Name -->
            <b-table-column
              :visible="columns.includes('name')"
              label="Item"
              cell-class="nowrap"
              v-slot="props">
              <Identifiers
                :variant="props.row"
                :showImage="false"
                nameInteraction="inspect"
                imageInteraction="none"
                @inspect="inspectVariant(props.row)"
              />
            </b-table-column>
            <!-- Additional Images -->
            <b-table-column
              :visible="columns.includes('images')"
              label="Images"
              cell-class="is-flex is-flex-wrap-wrap"
              v-slot="props">
              <template v-if="props.row.media.length > 1">
                <div v-for="(media,index) in props.row.media" :key="index" class="m-2">
                  <p><a :href="media.url|cloudinary" target="_blank"><img class="rounded-lg" :src="media.url | cloudinary(60,60, 'fill')" width="60" height="60" /></a></p>
                  <p class='has-text-grey-light is-size-8'>{{ media.width }} x {{ media.height }}</p>
                </div>
              </template>
              <template v-else>
                <div class="column" style="height:100px;">No Additional Images Uploaded</div>
              </template>
            </b-table-column>
            <!-- Cost -->
            <b-table-column
              :visible="showCost && columns.includes('offers')"
              label="Cost"
              cell-class="nowrap"
              width="128"
              centered
              v-slot="props">
              <p v-if="'retailer_cost' in props.row.proposal_variant.pricing">
                <CurrencyLocale :value="getRetailerCost(props.row)" :currency="props.row.proposal_variant.pricing.retailer_cost.values[0].unit"/>
              </p>
              <p v-else class="has-text-grey-light">Not Set</p>
            </b-table-column>
            <!-- Price -->
            <b-table-column
              :visible="columns.includes('offers')"
              label="Sugg. Retail"
              cell-class="nowrap"
              width="128"
              centered
              v-slot="props">
              <p v-if="'retailer_price' in props.row.proposal_variant.pricing">
                <CurrencyLocale :value="parseFloat(props.row.proposal_variant.pricing.retailer_price.values[0].value)" :currency="props.row.proposal_variant.pricing.retailer_price.values[0].unit"/>
              </p>
              <p v-else class="has-text-grey-light">Not Set</p>
            </b-table-column>
            <!-- Margin -->
            <b-table-column
              :visible="showCost && columns.includes('offers')"
              label="Est. Margin"
              cell-class="nowrap"
              width="128"
              centered
              v-slot="props">
              <Margin :pricing="props.row.proposal_variant.pricing" />
            </b-table-column>
            <!-- Status -->
            <b-table-column
              :visible="columns.includes('offers')"
              label="Status"
              cell-class="nowrap has-text-weight-bold"
              width="128"
              centered
              v-slot="props">
              <span v-if="props.row.proposal_variant.rejected_at === null" class="has-text-success">Included</span>
              <span v-else class="is-italic has-text-grey-light">Removed</span>
            </b-table-column>
            <!-- Product SKU -->
            <b-table-column
              :visible="columns.includes('skus')"
              label="Product SKU"
              cell-class="nowrap"
              centered
              width="164"
              v-slot="props">
              <span v-if="'parent_identifier' in props.row.attributes">
                {{ props.row.attributes.parent_identifier.values[0].value }}
              </span>
              <span v-else>--</span>
            </b-table-column>
            <!-- Supplier SKU -->
            <b-table-column
              :visible="columns.includes('skus')"
              label="Supplier SKU"
              cell-class="nowrap"
              centered
              width="164"
              v-slot="props">
              {{ props.row.brand_identifier }}
            </b-table-column>
            <!-- Retailer SKU -->
            <b-table-column
              :visible="columns.includes('skus')"
              label="Merchant SKU"
              cell-class="nowrap"
              centered
              width="164"
              v-slot="props">
              <span v-if="props.row.retailer_identifiers.length > 0">
                {{ props.row.retailer_identifiers[0].identifier }}
              </span>
              <span v-else class="has-text-success has-text-weight-bold">
                New SKU
              </span>
            </b-table-column>
            <!-- Compatibility -->
            <b-table-column
              :visible="columns.includes('compatibility')"
              label="Compatibility"
              cell-class="nowrap has-text-weight-bold"
              centered
              width="96"
              v-slot="props">
              <span v-if="props.row.is_compatible" class="has-text-success">Passing</span>
              <span v-else class="has-text-danger">Failing</span>
            </b-table-column>
            <!-- Issues -->
            <b-table-column
              :visible="columns.includes('issues')"
              label="Issues"
              cell-class="nowrap has-text-weight-bold"
              centered
              width="175"
              v-slot="props">
              <span v-if="props.row.proposal_variant.issues.length">
                <router-link class="has-text-info" :to="{ name: 'proposal.detail.issues', params: { id: proposal.id } }">
                  {{props.row.proposal_variant.issues.length}} (View All)
                </router-link>
              </span>
              <span v-else class="has-text-danger">No Open Issues</span>
            </b-table-column>
            <!-- Cartons -->
            <b-table-column
              :visible="showCost && columns.includes('cartons')"
              label="# Cartons"
              cell-class="nowrap"
              centered
              v-slot="props">
              <span v-if="props.row.cartons.length">{{props.row.cartons.length}} </span>
              <span v-else>N/A</span>
            </b-table-column>
            <!-- Dimensions -->
            <b-table-column
              :visible="showCost && columns.includes('cartons')"
              label="Dimensions"
              cell-class="nowrap"
              centered
              v-slot="props">
              <div v-for="carton in props.row.cartons" :key="carton.id">
                <Carton :dimensions="carton"></carton>
              </div>
            </b-table-column>
          </template>
          <template #empty>
            <div class="py-xxl has-text-grey has-text-centered">
              {{ (loading) ? "Loading data..." : 'No items found!' }}
            </div>
          </template>
        </b-table>
      </div>
      <b-pagination
        :total="total"
        :current.sync="currentPage"
        :rounded="true"
        :per-page="perPage"
        @change="paginate">
      </b-pagination>
    </div>
    <!-- Inspect Variant -->
    <b-modal
      v-if="(inspectProposalVariant !== null)"
      has-modal-card
      :active="(inspectProposalVariant !== null)"
      :can-cancel="['escape', 'x']"
      @close="closeModals"
      ><ProposalVariantModal :proposal="proposal" :selectedVariant="inspectProposalVariant" />
    </b-modal>
    <!-- Remove Items Modal -->
    <b-modal
      has-modal-card
      :active.sync="(showRemoveItemsModal)"
      :can-cancel="['escape', 'x']"
      ><RemoveProposalVariantsModal :proposal="proposal" :variants="selected" @variants:removed="reload" />
    </b-modal>
    <!-- Create Issues Modal -->
    <b-modal
      has-modal-card
      :active.sync="(showCreateIssueModal)"
      :can-cancel="['escape', 'x']"
      ><CreateProposalIssueModal :proposal="proposal" :variants="selected" @issue:created="reload"/>
    </b-modal>
    <!-- Add to Open Issues Modal -->
    <b-modal
      has-modal-card
      :active.sync="(showAddToOpenIssueModal)"
      :can-cancel="['escape', 'x']"
      ><ModifyProposalIssueModal :proposal="proposal" :variants="selected" @issue:created="reload" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Filterable from '@/mixins/Filterable'
import Identifiers from '@/components/Models/Variant/Identifiers'
import Margin from '@/components/Presenters/ProposalVariant/Margin'
import Carton from '@/components/Presenters/Carton'
import ProposalVariantModal from '@/components/Models/Proposal/Modals/ProposalVariantModal'
import RemoveProposalVariantsModal from '@/components/Models/Proposal/Modals/RemoveProposalVariants'
import CreateProposalIssueModal from '@/components/Models/ProposalIssue/Modals/Create'
import ModifyProposalIssueModal from '@/components/Models/ProposalIssue/Modals/Modify'
import ProposalVariantFilters from './ProposalVariantFilters'

export default {
  components: {
    Identifiers,
    Margin,
    Carton,
    ProposalVariantModal,
    RemoveProposalVariantsModal,
    ProposalVariantFilters,
    CreateProposalIssueModal,
    ModifyProposalIssueModal
  },
  data () {
    return {
      tabView: 'offers',
      key: 'proposal.variants.' + this.proposal.id,
      showImages: true,
      alwaysAttach: {
        added_to_proposal: 1,
        is_rejected: 0,
        ruleset_variant_summary: 1,
        short: 1
      },
      // modals
      inspectProposalVariant: null,
      showRemoveItemsModal: false,
      showCreateIssueModal: false,
      showAddToOpenIssueModal: false
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/proposals/' + this.proposal.id + '/variants/'
    },
    columns () {
      if (this.tabView === 'offers') return ['name', 'offers']
      if (this.tabView === 'skus') return ['name', 'skus']
      if (this.tabView === 'images') return ['name', 'images']
      if (this.tabView === 'compatibility') return ['name', 'compatibility']
      if (this.tabView === 'issues') return ['name', 'issues']
      if (this.tabView === 'shipping') return ['name', 'cartons', 'dimensions']
      return ['name']
    },
    canRemoveItems () {
      if (this.context.isSupplier()) {
        const allowed = ['draft', 'revised']
        if (allowed.includes(this.proposal.status)) {
          return true
        }
      }
      if (this.context.isMerchant()) {
        const allowed = ['proposed']
        if (allowed.includes(this.proposal.status)) {
          return true
        }
      }
      return false
    },
    showCost () {
      return (this.connection.connection_fees.net_price_method !== 'revshare')
    }
  },
  methods: {
    closeModals () {
      this.inspectProposalVariant = null
    },
    reload () {
      this.selected = []
      this.load()
      this.$emit('proposal:updated')
    },
    inspectVariant (variant) {
      this.inspectProposalVariant = variant
    },
    getRetailerCost (row) {
      let retailerCost = row.proposal_variant.pricing.retailer_cost.values[0]
      if (row.proposal_variant.pricing.retailer_cost.values.length > 1) {
        const values = row.proposal_variant.pricing.retailer_cost.values
        // fetch the last created value
        retailerCost = values.reduce((a, b) => a.created_at > b.created_at ? a : b)
      }
      return parseFloat(retailerCost.value)
    }
  },
  mounted () {
    this.load()
  },
  mixins: [Filterable],
  props: {
    proposal: {
      type: Object,
      required: true
    },
    connection: {
      type: Object,
      required: true
    },
    isProductProposal: {
      type: Boolean,
      default: false
    }
  }
}
</script>
