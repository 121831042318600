<template>
  <div class="has-background-white px-md rounded-lg mb-xxs">
    <router-link :to="routeTo">
      <div class="columns is-mobile is-vcentered is-marginless">
        <div class="column">
          <p class="has-text-weight-bold">{{ label }}</p>
          <p class="has-text-grey-light is-size-7">{{ description }}</p>
        </div>
        <div class="column is-one-fifth">
          <div class="has-background-white-ter pa-xs rounded-lg has-text-weight-bold has-text-centered">
            <span v-if="!loading" :class="textClass">{{ count }}</span>
            <span v-else><b-icon icon="loading" custom-class="mdi-spin" size="is-small"></b-icon></span>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['context']),
    textClass () {
      return (this.alert && parseInt(this.count) > 0) ? 'has-text-danger' : ''
    },
    routeTo () {
      if (this.isProductProposal) {
        return {
          name: 'product.proposals.list'
        }
      } else {
        return {
          name: 'proposals.list', query: { preset: this.preset }
        }
      }
    }
  },
  props: {
    preset: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    description: {
      type: String,
      default: null
    },
    count: {
      type: Number,
      default: 0
    },
    alert: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: true
    },
    isProductProposal: {
      type: Boolean,
      default: false
    }
  }
}
</script>
