<template>
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="mb-sm">
        <div class="columns is-centered">
          <div class="column is-one-third">
            <h1 class="has-text-centered is-size-4">Products</h1>
          </div>
        </div>
      </div>
      <!-- Action Items -->
      <div>
        <div class="columns is-centered">
          <div class="column is-full-tablet is-four-fifths-widescreen">
            <!-- Search -->
            <div class="rounded-lg has-background-white mb-lg px-lg py-sm">
              <item-search />
            </div>
            <div class="columns">
              <!-- Browse -->
              <div class="column">
                <div class="mb-lg">
                  <p class="has-text-weight-bold has-text-centered mb-sm pt-xs">Browse & Review</p>
                  <!-- Products -->
                  <router-link v-if="context.partner.id !== 1125" :to="{ name: 'catalog.products' }">
                    <div class="rounded-lg has-background-white mb-xxs pa-md px-xl">
                      <div class="columns is-vcentered is-mobile">
                        <div class="column">
                          <div class="">
                            <h4 class="has-text-weight-bold">Products</h4>
                            <p class="has-text-grey is-size-7">
                              Browse your {{ app.platform }} catalog by parent product.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </router-link>
                  <!-- Items -->
                  <router-link :to="{ name: 'catalog.items' }">
                    <div class="rounded-lg has-background-white mb-xxs pa-md px-xl">
                      <div class="columns is-vcentered is-mobile">
                        <div class="column">
                          <div class="">
                            <h4 class="has-text-weight-bold">Items</h4>
                            <p class="has-text-grey is-size-7">
                              Browse your {{ app.platform }} catalog by item SKU.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </router-link>
                  <!-- Inventory -->
                  <div class="rounded-lg has-background-white mb-xxs pa-md px-xl">
                    <div class="columns is-vcentered is-mobile">
                      <div class="column">
                        <div class="">
                          <router-link :to="{ name: 'catalog.inventory' }">
                            <h4 class="has-text-weight-bold">Inventory</h4>
                          </router-link>
                          <p class="has-text-grey is-size-7">
                            Review inventory positions by item.
                          </p>
                        </div>
                      </div>
                      <div v-if="context.isMerchant()" class="column is-narrow">
                        <router-link :to="{ name: 'catalog.inventory.summary' }" class="is-size-7 has-text-info">By Vendor</router-link>
                      </div>
                    </div>
                  </div>
                  <!-- Permits -->
                  <router-link :to="{ name: 'catalog.offers' }">
                    <div class="rounded-lg has-background-white mb-xxs pa-md px-xl">
                      <div class="columns is-vcentered is-mobile">
                        <div class="column">
                          <div class="">
                            <h4 class="has-text-weight-bold">Offers</h4>
                            <p class="has-text-grey is-size-7">
                              Review offers by item.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
              <!-- Import & Add -->
              <div class="column">
                <!-- Proposals -->
                <div class="mb-md">
                  <p class="has-text-weight-bold has-text-centered mb-sm pt-xs">
                    {{ (context.isMerchant()) ? 'Curate' : 'Submit' }}
                  </p>
                  <router-link :to="{ name: 'proposals.dashboard' }">
                    <div class="rounded-lg has-background-white mb-xxs pa-md px-xl">
                      <div class="columns is-vcentered   is-mobile">
                        <div class="column">
                          <div class="">
                            <h4 class="has-text-weight-bold">Proposals</h4>
                            <p class="has-text-grey is-size-7">
                              {{ (context.isMerchant()) ? 'Review proposals from suppliers.' : 'Submit products to merchants.' }}
                            </p>
                          </div>
                        </div>
                        <div v-if="context.isMerchant()" class="column is-one-fifth">
                          <div v-if="!loading" class="has-background-white-ter pa-xs rounded-lg has-text-weight-bold has-text-centered">
                            <b-tooltip :label="'Proposals that are awaiting your approval.'" size="is-small" multilined>
                              <router-link :to="{ name: 'proposals.dashboard' }" class="has-text-danger">{{proposedCount}}</router-link>
                            </b-tooltip>
                          </div>
                          <div v-else><b-icon icon="loading" custom-class="mdi-spin" size="is-small"></b-icon></div>
                        </div>
                      </div>
                    </div>
                  </router-link>
                  <router-link v-if="context.isMerchant() && features.includes('feature_single_product_proposals')" :to="{ name: 'merchandise.proposals' }">
                    <div class="rounded-lg has-background-white mb-xxs pa-md px-xl">
                      <div class="columns is-vcentered   is-mobile">
                        <div class="column">
                          <div class="">
                            <h4 class="has-text-weight-bold">New Products</h4>
                            <p class="has-text-grey is-size-7">
                              {{ (context.isMerchant()) ? 'Review new products from suppliers.' : 'Submit products to merchants.' }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </router-link>
                </div>
                <p class="has-text-weight-bold has-text-centered mb-sm pt-xs">Import</p>
                <!-- Import Requests -->
                <router-link :to="{ name: 'catalog.import.requests' }">
                  <div class="rounded-lg has-background-white mb-xxs pa-md px-xl">
                    <div class="columns is-vcentered is-mobile">
                      <div class="column">
                        <div class="">
                          <h4 class="has-text-weight-bold">Import Requests</h4>
                          <p class="has-text-grey is-size-7">
                            Review proposals from your merchant partners.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </router-link>
                <!-- Import Products -->
                <router-link :to="{ name: 'catalog.import.products' }">
                  <div class="rounded-lg has-background-white mb-xxs pa-md px-xl">
                    <div class="columns is-vcentered is-mobile">
                      <div class="column">
                        <div class="">
                          <h4 class="has-text-weight-bold">Import Products & Attributes</h4>
                          <p class="has-text-grey is-size-7">
                            Import product data into the {{ app.platform }} platform.
                          </p>
                        </div>
                      </div>
                      <div class="column is-narrow">
                        <router-link :to="{ name: 'catalog.import.history' }" class="has-text-info">History</router-link>
                      </div>
                    </div>
                  </div>
                </router-link>
                <!-- Import Inventory -->
                <router-link v-if="context.isSupplier()" :to="{ name: 'catalog.import.inventory.data' }">
                  <div class="rounded-lg has-background-white mb-xxs pa-md px-xl">
                    <div class="columns is-vcentered is-mobile">
                      <div class="column">
                        <div class="">
                          <h4 class="has-text-weight-bold">Import Inventory</h4>
                          <p class="has-text-grey is-size-7">
                            Import inventory positions into the {{ app.platform }} platform.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ItemSearch from '../components/ItemSearch'
export default {
  components: {
    ItemSearch
  },
  computed: {
    ...mapGetters(['app', 'auth', 'context']),
    endpoint () {
      return this.context.route + '/proposals/summary/'
    },
    proposedCount () {
      return ('proposed' in this.statuses) ? this.statuses.proposed.count : 0
    },
    loading () {
      return Boolean(this.loadingFeatures || this.loadingProposalSummary)
    }
  },
  data () {
    return {
      loadingProposalSummary: true,
      loadingFeatures: true,
      features: [],
      statuses: {}
    }
  },
  methods: {
    loadProposalSummary () {
      this.loadingProposalSummary = true
      this.$http.get(this.endpoint).then(response => {
        this.statuses = this.$utils.keyBy(response.data, (el) => {
          return el.status
        })
      }).finally(() => {
        this.loadingProposalSummary = false
      })
    },
    loadFeatures () {
      this.loadingFeatures = true
      const endpoint = this.context.route + '/preferences/?grouping=onboarding'
      this.$http.get(endpoint).then(response => {
        response.data.results.forEach(feature => {
          if (feature.code.match(/^feature_.*$/g)) {
            if (feature.retailer_preference && feature.retailer_preference.value === '1') {
              this.features.push(feature.code)
            }
          }
        })
        this.loadingFeatures = false
      })
    }
  },
  mounted () {
    if (this.context.isMerchant()) {
      this.loadFeatures()
      this.loadProposalSummary()
    }
  }
}
</script>
