<template>
  <div>
    <b-field
      :type="validator.inputFieldClass"
      :message="validator.inputFieldMessage"
      grouped
      expanded>
      <b-numberinput
        v-model="currentValue"
        placeholder=""
        :disabled="!isEditable"
        :readonly="!isEditable"
        :controls="false"
        :step="0.01"
        @input="announce"
      ></b-numberinput>
      <b-select
        v-if="hasUnits"
        v-model="currentUnits"
        :disabled="!isEditable"
        :readonly="!isEditable">
        <option v-for="unit in attribute.rule.attribute.available_units" :key="unit.id">{{ unit.name }}</option>
      </b-select>
    </b-field>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['context']),
    isEditable () {
      if (this.context.isMerchant()) {
        if (this.attribute.rule.attribute.editable_by === 'brand') return false
        return true
      }
      return true
    },
    hasUnits () {
      return Boolean(this.attribute.rule.attribute.available_units.length)
    },
    validator () {
      const validator = {
        inputFieldClass: null,
        inputFieldMessage: null
      }
      if (this.attribute.rule.is_required === true && (this.currentValue === null || this.currentValue === '')) {
        return {
          inputFieldClass: 'is-danger',
          inputFieldMessage: 'A value for this field is required.'
        }
      }
      if (this.originalValue !== this.currentValue) {
        return {
          inputFieldClass: 'is-info',
          inputFieldMessage: null
        }
      }
      return validator
    }
  },
  data () {
    return {
      originalValue: null,
      originalUnits: null,
      currentValue: null,
      currentUnits: null
    }
  },
  methods: {
    announce () {
      this.$emit('input', {
        attribute: {
          code: this.attribute.rule.attribute.code
        },
        value: this.currentValue
      })
    }
  },
  mounted () {
    if (this.attribute.value) {
      this.originalValue = this.currentValue = Number(this.attribute.value.values[0]?.value)
      if (this.attribute.rule.attribute.available_units.length) {
        // if units are set, use them; else use the default units...
        if (this.attribute.value.values[0].unit) {
          this.originalUnits = this.currentUnits = this.attribute.value.values[0].unit.name
        } else {
          this.originalUnits = this.currentUnits = this.attribute.rule.attribute.default_unit.name
        }
      }
    }
  },
  props: {
    attribute: {
      type: Object,
      required: true
    }
  }
}
</script>
