<template>
<div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Decline Proposal?</p>
    </header>
    <section class="modal-card-body">
      <div class="py-xl has-text-centered">
        <p>
          Are you sure you want to decline this product?
        </p>
        <p>
          This action cannot be undone and the supplier may receive a notification.
        </p>
      </div>
    </section>
    <footer class="modal-card-foot space-between" >
      <b-button @click="$parent.close()">Close</b-button>
      <b-button
        type="is-primary"
        :disabled="saving"
        :loading="saving"
        @click="save">Decline Proposal</b-button>
    </footer>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['context'])
  },
  data () {
    return {
      saving: false
    }
  },
  methods: {
    save () {
      this.saving = true
      const endpoint = this.context.route + '/proposals/' + this.proposal.id + '/'
      const body = {
        status: 'declined'
      }
      this.$http.patch(endpoint, body).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Proposal declined!' })
        this.$parent.close()
        this.$emit('proposal:declined')
      }).finally(() => {
        this.saving = false
      })
    }
  },
  props: ['proposal']
}
</script>
