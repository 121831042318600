<template>
  <div>
    <div class="has-text-centered mb-md">
      <p class="has-text-weight-bold">Import Requests</p>
    </div>
    <div class="has-background-white px-md rounded-lg mb-xxs">
      <router-link :to="{ name: 'catalog.import.requests' }">
        <div class="columns is-mobile is-vcentered is-marginless">
          <div class="column">
            <p class="has-text-weight-bold">Pending Import Requests</p>
            <p class="has-text-grey-light is-size-7">Review & approve product imports submitted by a merchant partner.</p>
          </div>
          <div class="column is-one-fifth">
            <div class="has-background-white-ter pa-xs rounded-lg has-text-weight-bold has-text-centered">
              <span v-if="!loading" :class="textClass">{{ importRequests }}</span>
              <span v-else><b-icon icon="loading" custom-class="mdi-spin" size="is-small"></b-icon></span>
            </div>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['context']),
    loading () {
      return Boolean(this.loadingImportRequests)
    },
    textClass () {
      return null
    }
  },
  data () {
    return {
      loadingImportRequests: true,
      importRequests: []
    }
  },
  methods: {
    loadImportRequests () {
      const endpoint = this.context.route + '/feed-requests/'
      const query = {
        params: {
          status: 'pending'
        }
      }
      this.$http.get(endpoint, query).then(response => {
        this.importRequests = response.data.count
        this.loadingImportRequests = false
      })
    }
  },
  mounted () {
    this.loadImportRequests()
  }
}
</script>
