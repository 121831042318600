<template>
  <div class="mt-xl">
    <!-- Share -->
    <div v-if="hasSingleProductProposals" class="mb-lg">
      <p class="has-text-weight-bold has-text-centered mb-md">Share Products</p>
      <div class="columns is-centered is-multiline">
        <div class="column is-two-thirds-tablet is-half-desktop">
          <router-link :to="{ name: 'catalog.merchandise' }">
            <div class="has-background-white rounded-lg mb-xs">
              <div class="py-md px-lg">
                <p class="has-text-weight-bold">Share Products</p>
                <p class="has-text-grey-light is-size-7">
                  Send and manage products with merchant partners.
                </p>
              </div>
            </div>
          </router-link >
        </div>
      </div>
    </div>
    <!-- Browse Items -->
    <div class="mb-lg">
      <p class="has-text-weight-bold has-text-centered mb-md">Review &amp; Export</p>
      <div class="columns is-centered is-multiline">
        <div class="column is-two-thirds-tablet is-half-desktop">
          <router-link :to="{ name: 'catalog.items' }">
            <div class="has-background-white rounded-lg mb-xs">
              <div class="py-md px-lg">
                <p class="has-text-weight-bold">Browse Products &amp; Inventory</p>
                <p class="has-text-grey-light is-size-7">
                  Review item attributes &amp; inventory positions.
                </p>
              </div>
            </div>
          </router-link >
          <router-link :to="{ name: 'catalog.offers' }">
            <div class="has-background-white rounded-lg mb-xs">
              <div class="py-md px-lg">
                <p class="has-text-weight-bold">Browse Offers</p>
                <p class="has-text-grey-light is-size-7">
                  Review item costs and pricing.
                </p>
              </div>
            </div>
          </router-link >
        </div>
      </div>
    </div>
    <!-- Merchandise -->
    <div class="mb-md">
      <p class="has-text-weight-bold has-text-centered mb-md">Merchandise</p>
      <div class="columns is-centered is-multiline">
        <div class="column is-two-thirds-tablet is-half-desktop">
          <!-- Start New Proposal -->
          <router-link :to="{ name: 'catalog.submit.new' }">
            <div class="has-background-white rounded-lg mb-xxs">
              <div class="py-md px-lg">
                <p class="has-text-weight-bold">Submit New Proposal</p>
                <p class="has-text-grey-light is-size-7">
                  Submit a merchandsing proposal to a merchant partner.
                </p>
              </div>
            </div>
          </router-link >
          <router-link :to="{ name: 'proposals.dashboard' }">
            <div class="has-background-white rounded-lg mb-xxs">
              <div class="columns is-mobile is-vcentered is-marginless py-xs px-sm">
                <div class="column">
                  <p class="has-text-weight-bold">Review Existing Proposals</p>
                  <p class="has-text-grey-light is-size-7">
                    Review the current status of your proposals.
                  </p>
                </div>
              </div>
            </div>
          </router-link >
          <router-link :to="{ name: 'catalog.import.requests' }">
            <div class="has-background-white rounded-lg mb-xxs">
              <div class="py-md px-lg">
                <p class="has-text-weight-bold">Review Import Requests</p>
                <p class="has-text-grey-light is-size-7">
                  Review and approve product imports submitted by a merchant partner.
                </p>
              </div>
            </div>
          </router-link >
        </div>
      </div>
    </div>
    <!-- Import -->
    <div class="mb-md">
      <div class="columns is-centered is-multiline">
        <div class="column is-two-thirds-tablet is-half-desktop">
          <div class="columns">
            <div class="column"></div>
            <div class="column">
              <p class="has-text-weight-bold has-text-centered mb-sm">Import</p>
            </div>
            <div class="column">
              <p class="has-text-right">
                <router-link class="has-text-info" :to="{ name: 'catalog.import.history' }">
                  Import History
                </router-link>
              </p>
            </div>
          </div>
          <router-link :to="{ name: 'catalog.import.inventory' }">
            <div class="has-background-white rounded-lg mb-xxs">
              <div class="py-md px-lg">
                <p class="has-text-weight-bold">Update Inventory</p>
                <p class="has-text-grey-light is-size-7">
                  Import current inventory from a CSV or spreadsheet.
                </p>
              </div>
            </div>
          </router-link >
          <router-link v-if="hasShopify" :to="{ name: 'catalog.import.shopify' }">
            <div class="has-background-white rounded-lg mb-xxs">
              <div class="py-md px-lg">
                <p class="has-text-weight-bold">Import from Shopify</p>
                <p class="has-text-grey-light is-size-7">
                  Import products from Shopify.
                </p>
              </div>
            </div>
          </router-link >
          <router-link :to="{ name: 'catalog.import.products' }">
            <div class="has-background-white rounded-lg mb-xxs">
              <div class="py-md px-lg">
                <p class="has-text-weight-bold">Import or Update Products from File</p>
                <p class="has-text-grey-light is-size-7">
                  Import or update product and variant attributes using a template file.
                </p>
              </div>
            </div>
          </router-link >
          <!-- Import Offers -->
          <router-link v-if="auth.isStaff && context.isSupplier()" :to="{ name: 'catalog.import.permits' }">
            <div class="has-background-white rounded-lg mb-xxs">
              <div class="py-md px-lg">
                <p class="has-text-weight-bold">Catalog Utilities</p>
                <p class="has-text-grey-light is-size-7">
                  Use these utilities to manage your items & offers in bulk.
                </p>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['app', 'auth', 'context']),
    hasShopify () {
      let hasShopify = false
      this.platformAccounts.forEach(account => {
        if (account.platform.code === 'shopify') hasShopify = true
      })
      return hasShopify
    },
    hasSingleProductProposals () {
      if (this.connections.length === 0) return false
      let enabled = false
      this.connections.forEach(connection => {
        if ('merchandise_via_product_proposals' in connection.options) enabled = true
      })
      return enabled
    }
  },
  data () {
    return {}
  },
  props: {
    connections: {
      type: Array,
      required: true
    },
    platformAccounts: {
      type: Array,
      required: true
    }
  }
}
</script>
