<template>
  <div class="section">
    <div class="container">
      <!-- Breadcrumbs -->
      <div class="py-sm">
        <div v-if="loading"><p class="has-text-grey-light">Loading...</p></div>
        <breadcrumbs v-else :history="breadcrumbs.history" :current="breadcrumbs.current" :hideBack="true" />
      </div>
      <!-- Product Header Component -->
      <div class="py-md">
        <ProposalHeader :loading="loading" :proposal="proposal" :product="product" @proposal:saved="loadProposal(true)"></ProposalHeader>
      </div>
      <!-- Proposal Tabs (Loaded) -->
      <div v-if="!loading">
        <div class="tabs">
          <ul>
            <router-link tag="li" :to="{ name: 'merchandise.proposal.attributes'}" exact><a>Attributes</a></router-link>
            <!-- <router-link tag="li" :to="{ name: 'merchandise.proposal.media'}" exact><a>Media</a></router-link> -->
            <router-link tag="li" :to="{ name: 'merchandise.proposal.items'}" exact><a>Pricing</a></router-link>
            <router-link v-if="context.isMerchant()" tag="li" :to="{ name: 'merchandise.proposal.metadata'}" exact><a>Metadata</a></router-link>
          </ul>
        </div>
        <div>
          <keep-alive>
            <router-view :connection="connection" :proposal="proposal" :product="product" @proposal:saved="loadProposal(true)" />
          </keep-alive>
        </div>
      </div>
      <!-- Loading State -->
      <div v-else class="py-lg has-text-centered">
        <div class="columns is-centered">
          <div class="column is-half">
            <p class="has-text-weight-bold has-text-grey-light mb-md">{{ loadingStage }}</p>
            <b-progress type="is-info" size="is-small" :value="loadingProgress"></b-progress>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ProposalHeader from '../components/Proposal/Header'
export default {
  components: {
    ProposalHeader
  },
  computed: {
    ...mapGetters(['app', 'context']),
    breadcrumbs () {
      if (this.loading || !this.proposal) return null
      return {
        current: '#' + this.proposal.id,
        history: [
          { label: 'Catalog', name: 'catalog.index' },
          { label: 'Merchandise', name: 'merchandise.proposals' }
        ]
      }
    },
    loading () {
      return Boolean(this.loadingProposal || this.loadingConnection || this.loadingProduct)
    },
    loadingStage () {
      if (this.loadingProposal) return 'Loading proposal details...'
      if (this.loadingConnection) return 'Loading proposal details...'
      if (this.loadingProduct) return 'Loading product...'
      return 'Done!'
    },
    loadingProgress () {
      let progress = 0
      if (!this.loadingProposal && this.proposal) progress += 25
      if (!this.loadingConnection && this.connection) progress += 25
      if (!this.loadingProduct && this.product) progress += 25
      return progress
    }
  },
  data () {
    return {
      loadingProposal: true,
      loadingConnection: true,
      loadingProduct: true,
      proposal: null,
      connection: null,
      product: null,
      selectedRulesetId: null,
      activeTab: 0
    }
  },
  methods: {
    loadProposal (quiet = false) {
      if (!quiet) this.loadingProposal = true
      const endpoint = this.context.route + '/proposals/' + this.$route.params.proposal + '/'
      this.$http.get(endpoint).then(response => {
        this.proposal = response.data
        this.loadProduct(quiet, this.proposal.products[0].id)
        this.loadConnection(quiet)
        this.loadingProposal = false
      })
    },
    loadConnection (quiet = false) {
      if (!this.proposal) return null
      if (!quiet) this.loadingConnection = true
      const endpoint = this.context.route + '/connections/' + this.proposal.connection.id + '/'
      this.$http.get(endpoint).then(response => {
        this.connection = response.data
        this.loadingConnection = false
      })
    },
    loadProduct (quiet = false, id) {
      if (!quiet) this.loadingProduct = true
      const endpoint = this.context.route + '/proposals/' + this.$route.params.proposal + '/products/' + id + '/'
      this.$http.get(endpoint).then(response => {
        this.product = response.data
        this.loadingProduct = false
      })
    }
  },
  mounted () {
    this.loadProposal()
  }
}
</script>
