<template>
  <div>
    <!-- Has Actions -->
    <div v-if="hasActions">
      <div class="">
        <!-- Actions -->
        <div class="buttons is-justify-content-flex-end">
          <b-button v-for="(action, index) in actions" :key="index" :type="(action.type === 'primary') ? 'is-info' : 'is-info is-light is-outlined'" :outlined="(action.type === 'primary') ? false : false" :size="size" @click="$emit(action.event)">{{ action.label }}</b-button>
          <template v-if="moreActions && moreActions.length">
          <div>
            <b-dropdown position="is-bottom-left" >
              <b-button slot="trigger" :size="size">
                <b-icon icon="dots-vertical"></b-icon>
              </b-button>
              <b-dropdown-item has-link v-for="(action, index) in moreActions" :key="index">
                <a href="#" @click.prevent="$emit(action.event)">{{ action.label }}</a>
              </b-dropdown-item>
            </b-dropdown>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['context']),
    hasActions () {
      return (this.actions.length > 0 || this.moreActions.length > 0)
    },
    actions () {
      const actions = []
      // proposed can be approved or returned
      if (this.proposal.status === 'proposed') {
        actions.push({ label: 'Approve', event: 'action:approve', type: 'primary' })
        actions.push({ label: 'Return to Supplier', event: 'action:revise', type: 'secondary' })
      }
      // approved can be pricing approved or returned
      if (this.proposal.status === 'approved') {
        if ('require_pricing_approval_on_proposals' in this.proposal.connection.options) {
          actions.push({ label: 'Manager Approve', event: 'action:pricing', type: 'primary' })
        } else {
          actions.push({ label: 'Complete', event: 'action:complete', type: 'secondary' })
        }
      }
      // pricing approved can be completed
      if (this.proposal.status === 'pricing_approved') {
        actions.push({ label: 'Complete', event: 'action:complete', type: 'secondary' })
      }
      // completed proposals can be published (and acknowledged)
      if (this.proposal.status === 'completed') {
        // actions.push({ label: 'Publish', event: 'action:publish', type: 'secondary' })
        if (this.proposal.acknowledged_at === null) {
          actions.push({ label: 'Mark as Complete!', event: 'action:acknowledge', type: 'secondary' })
        }
      }
      // all proposals can be exported
      // actions.push({ label: 'Export', event: 'action:export', type: 'secondary' })
      return actions
    },
    moreActions () {
      const moreActions = []
      const { status } = this.proposal
      // const { status, connection } = this.proposal
      // all proposals (except completed) can be reassigned...
      // if (status !== 'completed') {
      //   moreActions.push({ label: 'Change Owner', event: 'action:owner' })
      // }
      // not showing on proposed status
      // const list = ['proposed', 'pricing_approved']
      // if (list.includes(status) || (status === 'approved' && 'require_pricing_approval_on_proposals' in connection.options)) {
      //   moreActions.push({ label: 'Apply Transformers', event: 'action:applyTransformer' })
      // }
      // allow decline of 'proposed' proposals
      if (status === 'proposed') {
        // moreActions.push({ label: 'Merge Product into Existing Proposal', event: 'action:merge' })
        moreActions.push({ label: 'Decline Product', event: 'action:decline' })
      }
      // always actions
      moreActions.push({ label: 'Export', event: 'action:export', type: 'secondary' })

      return moreActions
    }
  },
  props: {
    size: {
      type: String,
      default: 'is-medium'
    },
    proposal: {
      type: Object,
      required: true
    }
  }
}
</script>
