import Index from './views/Index'
import Connection from './views/Connection'
import Submit from './views/Submit'
import Proposals from './views/Proposals'
import Proposal from './views/Proposal'
import Attributes from './components/Proposal/Attributes'
import Media from './components/Proposal/Media'
import Items from './components/Proposal/Items'
import Metadata from './components/Proposal/Metadata'

export default [{
  path: '/catalog/merchandise',
  name: 'catalog.merchandise',
  component: Index
}, {
  path: '/catalog/merchandise/connections/:connection/submit',
  name: 'merchandise.connection.submit',
  component: Submit
}, {
  path: '/catalog/merchandise/connections/:connection',
  name: 'merchandise.connection',
  component: Connection
}, {
  path: '/catalog/merchandise/proposals',
  name: 'merchandise.proposals',
  component: Proposals
}, {
  path: '/catalog/merchandise/proposals/:proposal',
  component: Proposal,
  children: [{
    path: 'attributes',
    name: 'merchandise.proposal.attributes',
    component: Attributes
  }, {
    path: 'media',
    name: 'merchandise.proposal.media',
    component: Media
  }, {
    path: 'items',
    name: 'merchandise.proposal.items',
    component: Items
  }, {
    path: 'metadata',
    name: 'merchandise.proposal.metadata',
    component: Metadata
  }]
}]
