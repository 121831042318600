<template>
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="mb-md">
        <div class="columns is-vcentered is-centered">
          <div class="column is-one-third">
            <h1 class="has-text-centered is-size-4">Product Proposals</h1>
          </div>
        </div>
      </div>
      <!-- Content -->
      <div v-if="context.isSupplier()">
        <DashboardSupplier :loading="loading" :statuses="statuses" :productProposalsCount="productProposalsCount"/>
      </div>
      <div v-if="context.isMerchant()">
        <DashboardMerchant :loading="loading" :statuses="statuses" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DashboardMerchant from '../components/Index/DashboardMerchant'
import DashboardSupplier from '../components/Index/DashboardSupplier'

export default {
  components: {
    DashboardMerchant,
    DashboardSupplier
  },
  computed: {
    ...mapGetters(['context']),
    breadcrumbs () {
      return {
        current: 'Proposals',
        history: [
          { label: 'Products', name: (this.context.isSupplier()) ? 'catalog' : 'catalog.index' }
        ]
      }
    },
    endpoint () {
      return this.context.route + '/proposals/summary/'
    },
    query () {
      return {
        params: {
          source: 'merchandising'
        }
      }
    }
  },
  data () {
    return {
      loading: true,
      statuses: {},
      productProposalsCount: 0
    }
  },
  methods: {
    load () {
      this.$http.get(this.endpoint).then(response => {
        this.statuses = this.$utils.keyBy(response.data, (o) => {
          return o.status
        })
      }).finally(() => {
        this.loading = false
      })
    },
    loadProductProposals () {
      const endpoint = this.context.route + '/proposals/'
      const query = {
        params: {
          short: 1,
          is_product_proposal: 1
        }
      }
      this.$http.get(endpoint, query).then(response => {
        this.productProposalsCount = response.data.count
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mounted () {
    this.load()
    this.loadProductProposals()
  },
  props: []
}
</script>
