<template>
  <div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Complete Proposal</p>
      </header>
      <section class="modal-card-body">
        <p class="py-xl has-text-centered">
          Are you sure you want to complete this proposal?
        </p>
      </section>
      <footer class="modal-card-foot space-between" >
        <b-button @click="$parent.close()">Close</b-button>
        <b-button
          type="is-primary"
          :disabled="saving"
          :loading="saving"
          @click="save">Complete</b-button>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['context'])
  },
  data () {
    return {
      saving: false,
      errors: {}
    }
  },
  methods: {
    save () {
      this.saving = true
      const endpoint = this.context.route + '/proposals/' + this.proposal.id + '/'
      const body = {
        status: 'completed'
      }
      this.$http.patch(endpoint, body).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Proposal Completed!' })
        this.$emit('proposal:completed')
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          this.errors = error.response.data.detail
          this.$buefy.toast.open({ type: 'is-danger', message: this.errors })
        } else {
          this.$buefy.toast.open({ type: 'is-danger', message: 'An unexpected error occurred!' })
        }
      }).finally(() => {
        this.$parent.close()
        this.saving = false
      })
    }
  },
  props: {
    proposal: {
      type: Object,
      required: true
    }
  }
}
</script>
