<template>
  <div>
    <!-- Has Actions -->
    <div v-if="hasActions">
      <div class="columns">
        <!-- Actions -->
        <div class="column is-one-quarter" v-for="action in actions" :key="action.id">
          <b-button v-if="action.type === 'primary'" type="is-primary" outlined expanded size="is-medium" @click="$emit(action.event)">{{ action.label }}</b-button>
          <b-button v-else type="is-light" expanded size="is-medium" @click="$emit(action.event)">{{ action.label }}</b-button>
        </div>
      </div>
    </div>
    <!-- Has No Actions -->
    <div v-else>
      <div class="columns">
        <div class="column"><b-button :disabled="true" >No Actions</b-button></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    hasActions () {
      return (this.actions.length > 0 || this.additionalActions.length > 0)
    },
    actions () {
      const actions = []
      // connections in 'onboarding' or 'suspended' state can be activated
      if (this.connection.status !== 'active') {
        actions.push({
          id: 'activate-connection',
          type: 'primary',
          label: 'Activate',
          event: 'activate:connection'
        })
      }
      // connections in 'onboarding' or 'active' state can be suspended
      if (this.connection.status !== 'suspended') {
        actions.push({
          id: 'suspend-connection',
          type: 'secondary',
          label: 'Suspend',
          event: 'suspend:connection'
        })
      }
      return actions
    },
    additionalActions () {
      return []
    }
  },
  props: {
    connection: {
      type: Object,
      required: true
    }
  }
}
</script>
