<template>
  <div>
    <!-- Proposal Ready -->
    <div v-if="!loading && proposal && product">
      <div class="columns is-vcentered is-mobile">
        <!-- Image -->
        <div class="column is-narrow">
          <div class="has-background-white pa-md border">
            <figure v-if="primaryMedia"
              class="image is-64x64 mb-xs">
              <img class="rounded-md" :src="cdnTransform(primaryMedia.url, 64, 64, 'fill', '2.0')" width="64" height="64" />
            </figure>
            <figure v-else class="image is-64x64">
              <img class="rounded-md" src="https://res.cloudinary.com/revcascade/image/upload/v1576868498/static/placeholder-lg.png" />
            </figure>
          </div>
        </div>
        <!-- Name -->
        <div class="column">
          <p class="is-size-4 has-text-black">{{ product.parent_name }}</p>
          <div class="columns is-vcentered">
            <div class="column">
              <p class="has-text-weight-bold has-text-grey mb-xs">Product # {{ product.parent_identifier }}</p>
              <div class="is-size-7">
                <div class="tags">
                  <StatusPresenter :proposal="proposal" />
                  <CompatibilityPresenter :proposal="proposal" />
                </div>
              </div>
            </div>
            <div class="column">
              <div v-if="context.isSupplier()">
                <WorkflowSupplier
                  :size="null"
                  :proposal="proposal"
                  @action:propose="showProposeModal = true"
                  @action:archive="showArchiveModal = true"
                  @action:export="showExportModal = true"
                />
              </div>
              <div v-else>
                <WorkflowMerchant
                  :size="null"
                  :proposal="proposal"
                  @action:owner="showChangeOwnerModal = true"
                  @action:revise="showReviseModal = true"
                  @action:approve="showApproveModal = true"
                  @action:pricing="showPriceApprovalModal = true"
                  @action:complete="showCompleteModal = true"
                  @action:publish="showPublishModal = true"
                  @action:acknowledge="showAcknowledgeModal = true"
                  @action:export="showExportModal = true"
                  @action:decline="showDeclineModal = true"
                  @action:applyTransformer="showTransformerModal = true" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Loading State -->
    <div v-else>
      <div class="columns is-vcentered">
        <div class="column is-narrow">
          <b-skeleton height="98" width="98" />
        </div>
        <div class="column">
          <p><b-skeleton height="25" /></p>
          <p class="mb-sm"><b-skeleton height="10" width="25%"/></p>
          <p><b-skeleton height="10" width="50%" /></p>
        </div>
        <div class="column is-one-quarter">
          <div class="columns">
            <div class="column"><b-skeleton /></div>
            <div class="column"><b-skeleton /></div>
          </div>
        </div>
      </div>
    </div>
    <!-- Workflow Modal: Approve -->
    <b-modal
      has-modal-card
      :active.sync="showApproveModal"
      :can-cancel="['escape', 'x']"
      ><ApproveModal :type="'proposal'" :proposal="proposal" @proposal:approved="$emit('proposal:saved')" />
    </b-modal>
    <!-- Workflow Modal: Price Approve -->
    <b-modal
      has-modal-card
      :active.sync="showPriceApprovalModal"
      :can-cancel="['escape', 'x']"
      ><PriceApproveModal :type="'proposal'" :proposal="proposal" @proposal:priced="$emit('proposal:saved')" />
    </b-modal>
    <!-- Workflow Modal: Complete -->
    <b-modal
      has-modal-card
      :active.sync="showCompleteModal"
      :can-cancel="['escape', 'x']"
      ><CompleteModal :type="'proposal'" :proposal="proposal" @proposal:completed="$emit('proposal:saved')" />
    </b-modal>
    <!-- Workflow Modal: Publish -->
    <b-modal
      has-modal-card
      :active.sync="showPublishModal"
      :can-cancel="['escape', 'x']"
      ><PublishModal :type="'proposal'" :proposal="proposal" @proposal:published="$emit('proposal:saved')" />
    </b-modal>
    <!-- Workflow Modal: Acknowledge -->
    <b-modal
      has-modal-card
      :active.sync="showAcknowledgeModal"
      :can-cancel="['escape', 'x']"
      ><AcknowledgeModal :type="'proposal'" :proposal="proposal" @proposal:acknowledged="$emit('proposal:saved')" />
    </b-modal>
    <!-- Workflow Modal: Revise -->
    <b-modal
      has-modal-card
      :active.sync="showReviseModal"
      :can-cancel="['escape', 'x']"
      ><ReviseModal :type="'proposal'" :proposal="proposal" @proposal:revised="$emit('proposal:saved')" />
    </b-modal>
    <!-- Workflow Modal: Propose / Resend -->
    <b-modal
      has-modal-card
      :active.sync="showProposeModal"
      :can-cancel="['escape', 'x']"
      ><ProposeModal :type="'proposal'" :proposal="proposal" @proposal:proposed="$emit('proposal:saved')" />
    </b-modal>
    <!-- Workflow Modal: Decline -->
    <b-modal
      has-modal-card
      :active.sync="showDeclineModal"
      :can-cancel="['escape', 'x']"
      ><DeclineModal :type="'proposal'" :proposal="proposal" @proposal:declined="$emit('proposal:saved')" />
    </b-modal>
    <!-- Workflow Modal: Archive -->
    <b-modal
      has-modal-card
      :active.sync="showArchiveModal"
      :can-cancel="['escape', 'x']"
      ><ArchiveModal :type="'proposal'" :proposal="proposal" @proposal:archived="$emit('proposal:saved')" />
    </b-modal>
    <!-- Workflow Modal: Export -->
    <b-modal
      has-modal-card
      :active.sync="showExportModal"
      :can-cancel="['escape', 'x']"
      ><ExportModal :type="'proposal'" :proposal="proposal" @proposal:exported="$emit('proposal:saved')" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CdnTransform from '@/mixins/CdnTransform'
import StatusPresenter from '@/components/Models/Proposal/Presenters/StatusTag'
import CompatibilityPresenter from '@/components/Models/Proposal/Presenters/CompatibilityTag'
import WorkflowMerchant from './WorkflowMerchant'
import WorkflowSupplier from './WorkflowSupplier'
import ApproveModal from '@/components/Models/ProductProposal/Modals/Approve'
import ArchiveModal from '@/components/Models/ProductProposal/Modals/Archive'
import ProposeModal from '@/components/Models/ProductProposal/Modals/Propose'
import ReviseModal from '@/components/Models/ProductProposal/Modals/Revise'
import DeclineModal from '@/components/Models/ProductProposal/Modals/Decline'
import PriceApproveModal from '@/components/Models/ProductProposal/Modals/PriceApprove'
import CompleteModal from '@/components/Models/ProductProposal/Modals/Complete'
import PublishModal from '@/components/Models/ProductProposal/Modals/Publish'
import ExportModal from '@/components/Models/ProductProposal/Modals/Export'
import AcknowledgeModal from '@/components/Models/Proposal/Modals/Acknowledge'
// import CostUpdateModal from '@/components/Models/Proposal/Modals/CostUpdate'
// import TransformerModal from '@/components/Models/Transformers/Modals/ApplyTransformer'

export default {
  components: {
    CompatibilityPresenter,
    StatusPresenter,
    WorkflowMerchant,
    WorkflowSupplier,
    AcknowledgeModal,
    ApproveModal,
    ArchiveModal,
    CompleteModal,
    DeclineModal,
    ExportModal,
    PriceApproveModal,
    ProposeModal,
    PublishModal,
    ReviseModal
  },
  computed: {
    ...mapGetters(['context']),
    primaryMedia () {
      if (!this.product) return null
      if (this.product.primary_media !== null && this.product.primary_media.media !== 'default') {
        return this.product.primary_media
      } else if ('media' in this.product && this.product.media.length) {
        return this.product.media[0]
      }
      return null
    }
  },
  data () {
    return {
      showTagsInput: false,
      showApproveModal: false,
      showPriceApprovalModal: false,
      showCompleteModal: false,
      showPublishModal: false,
      showAcknowledgeModal: false,
      showArchiveModal: false,
      showReviseModal: false,
      showProposeModal: false,
      showDeclineModal: false,
      showExportModal: false
    }
  },
  methods: {
    returnToList () {
      this.$router.push({ name: 'merchandise.proposals' })
    }
  },
  mixins: [CdnTransform],
  props: {
    proposal: {
      type: Object || null
    },
    product: {
      type: Object || null
    },
    loading: {
      type: Boolean
    }
  }
}
</script>
