<template>
  <div>
    <!-- Media Viewer -->
    <div class=" mb-md">
      <div class="has-background-white rounded-md pa-md px-lg mb-xxs">
        <div class="columns is-mobile">
          <div class="column">
            <p class="has-text-weight-bold">Media</p>
          </div>
          <div class="column is-narrow">
            <div class="buttons">
              <b-button size="is-small" @click="toggleView('gallery')" :disabled="view === 'gallery'" icon-left="view-gallery-outline"></b-button>
              <b-button size="is-small" @click="toggleView('table')" :disabled="view === 'table'" icon-left="format-list-bulleted"></b-button>
              <b-button size="is-small" @click="toggleCollapse()" :icon-left="(isCollapsed) ? 'arrow-expand' : 'arrow-collapse'"></b-button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isCollapsed" class="has-background-white rounded-md pa-lg">
        <div v-if="view === 'gallery'">
          <div v-if="media.length" class="columns">
            <div class="column is-half">
              <div class="mb-sm">
                <figure class="image border pa-xs">
                  <img class="rounded-md" :src="media[mediaIndex].cdnUrl" />
                </figure>
                <p class="has-text-centered is-size-7 has-text-weight-bold mt-sm">{{ media[mediaIndex].name }}</p>
              </div>
            </div>
            <div class="column is-half">
              <div class="columns is-mobile is-multiline is-variable is-1">
                <div v-for="(image, index) in media" :key="image.id" class="column is-one-fifth">
                  <a href="#" @click.prevent="focusImage(index)">
                    <figure class="image border pa-xs" :class="(index === mediaIndex) ? 'has-border-primary' : ''">
                      <img class="rounded-md" :src="media[index].cdnUrl" />
                    </figure>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="is-size-7 has-text-centered has-text-grey-light pa-xl">
            <template v-if="variant">
              No images are associated with this variant. <br/>
              Please select another variant revert back to viewing all product images.
            </template>
            <template v-else>
              No images are associated with this product. <br/>
              Please select a variant above to view images for specific variants.
            </template>
          </div>
        </div>
        <div v-else>
          <table v-if="mediaAttributes.length"  class="table is-size-7 is-fullwidth is-narrow">
            <tbody>
              <tr>
                <th>Image</th>
                <th>Supplier URL</th>
                <th class="has-text-centered">Saved?</th>
              </tr>
              <tr v-for="attribute in mediaAttributes" :key="attribute.id">
                <td>{{ attribute.name }}</td>
                <td>
                  <span v-if="attribute.rawUrl">{{ attribute.rawUrl}}</span>
                  <span v-else class="has-text-grey-light">- Not Provided -</span>
                </td>
                <td class="has-text-centered">
                  <b-icon v-if="attribute.cdnUrl" class="has-text-success" icon="check"></b-icon>
                  <b-icon v-else-if="attribute.rawUrl" class="has-text-danger" icon="cancel"></b-icon>
                  <span v-else class="has-text-grey-light">- N/A -</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['app', 'context']),
    media () {
      const media = []
      this.mediaAttributes.forEach(attribute => {
        if (attribute.cdnUrl) {
          media.push(attribute)
        }
      })
      return media
    }
  },
  data () {
    return {
      view: 'gallery',
      isCollapsed: false,
      mediaAttributes: [],
      mediaIndex: 0
    }
  },
  methods: {
    loadMediaAttributes () {
      if (!this.ruleset) return []
      this.mediaAttributes = []
      this.ruleset.rule_groups.forEach(ruleGroup => {
        // if a variant is selected, build attributes off variant rules
        if (this.variant) {
          ruleGroup.rules.forEach(rule => {
            if (rule.target === 'variant' && rule.attribute.type === 'media') {
              const rawAttributeCode = rule.attribute.code
              const cdnAttributeCode = 'media_' + rule.attribute.code
              this.mediaAttributes.push({
                id: rule.id,
                name: rule.name,
                saved: true,
                rawUrl: (rawAttributeCode in this.variant.attributes) ? this.variant.attributes[rawAttributeCode].values[0].value : null,
                cdnUrl: (cdnAttributeCode in this.variant.attributes) ? this.variant.attributes[cdnAttributeCode].values[0].value : null
              })
            }
          })
        // otherwise, build attributes off product rules
        } else {
          ruleGroup.rules.forEach(rule => {
            if (rule.target === 'product' && rule.attribute.type === 'media') {
              const rawAttributeCode = rule.attribute.code
              const cdnAttributeCode = 'media_' + rule.attribute.code
              this.mediaAttributes.push({
                id: rule.id,
                name: rule.name,
                saved: true,
                rawUrl: (rawAttributeCode in this.product.attributes) ? this.product.attributes[rawAttributeCode].values[0].value : null,
                cdnUrl: (cdnAttributeCode in this.product.attributes) ? this.product.attributes[cdnAttributeCode].values[0].value : null
              })
            }
          })
        }
      })
    },
    focusImage (index) {
      this.mediaIndex = index
    },
    getImgUrl (value) {
      return this.media[value].url
    },
    toggleView (view) {
      this.view = view
    },
    toggleCollapse () {
      this.isCollapsed = !this.isCollapsed
    }
  },
  mounted () {
    this.loadMediaAttributes()
  },
  props: {
    proposal: {
      type: Object || null
    },
    product: {
      type: Object || null
    },
    ruleset: {
      type: Object || null
    },
    variant: {
      type: Object || null
    }
  },
  watch: {
    variant (value) {
      this.loadMediaAttributes()
    }
  }
}
</script>
