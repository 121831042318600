<template>
  <div>
    <div v-if="connection" class="mb-lg">
      <div>
        <div class="has-background-white rounded-lg px-md mb-xxs">
          <div class="columns is-mobile is-vcentered is-marginless">
            <div class="column">
              <p class="has-text-weight-bold">Share New Products</p>
              <p class="has-text-grey-light is-size-7">Submit new products to {{ connection.retailer.name }} for review.</p>
            </div>
            <div class="column is-one-fifth">
                <b-button type="is-info is-light rounded-md" expanded @click="navigateToSubmit"><b-icon icon="arrow-right" /></b-button>
            </div>
          </div>
        </div>
      </div >
    </div>
    <!-- Review Products -->
    <div  class="mb-lg">
      <div class="has-text-centered mb-md">
        <p class="has-text-weight-bold">Products</p>
      </div>
      <!-- Pending -->
      <div class="has-background-white px-md rounded-lg mb-xxs">
        <router-link :to="{ name: 'merchandise.proposals', query: { is_product_proposal: '1', status: 'proposed', connection_id: connection.id } }">
          <div class="columns is-mobile is-vcentered is-marginless">
            <div class="column"><p class="has-text-weight-bold">Pending</p></div>
            <div class="column is-one-fifth">
              <div class="has-background-white-ter pa-xs rounded-lg has-text-weight-bold has-text-centered">
                <span v-if="!loading" :class="productSummary.proposed.textClass">{{ productSummary.proposed.count }}</span>
                <span v-else><b-icon icon="loading" custom-class="mdi-spin" size="is-small"></b-icon></span>
              </div>
            </div>
          </div>
        </router-link>
      </div>
      <!-- Revised -->
      <div class="has-background-white px-md rounded-lg mb-xxs">
        <router-link :to="{ name: 'merchandise.proposals', query: { is_product_proposal: '1', status: 'revised', connection_id: connection.id } }">
          <div class="columns is-mobile is-vcentered is-marginless">
            <div class="column"><p class="has-text-weight-bold">Revised</p></div>
            <div class="column is-one-fifth">
              <div class="has-background-white-ter pa-xs rounded-lg has-text-weight-bold has-text-centered">
                <span v-if="!loading" :class="productSummary.revised.textClass">{{ productSummary.revised.count }}</span>
                <span v-else><b-icon icon="loading" custom-class="mdi-spin" size="is-small"></b-icon></span>
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <!-- Review Proposals -->
    <div >
      <div class="has-text-centered mb-md">
        <p class="has-text-weight-bold">Proposals</p>
      </div>
      <!-- Draft -->
      <div class="has-background-white px-md rounded-lg mb-xxs">
        <router-link :to="{ name: 'proposals.list', query: { is_product_proposal: '0', status: 'draft', connection_id: connection.id } }">
          <div class="columns is-mobile is-vcentered is-marginless">
            <div class="column"><p class="has-text-weight-bold">Draft</p></div>
            <div class="column is-one-fifth">
              <div class="has-background-white-ter pa-xs rounded-lg has-text-weight-bold has-text-centered">
                <span v-if="!loading" :class="summary.draft.textClass">{{ summary.draft.count }}</span>
                <span v-else><b-icon icon="loading" custom-class="mdi-spin" size="is-small"></b-icon></span>
              </div>
            </div>
          </div>
        </router-link>
      </div>
      <!-- Proposed -->
      <div class="has-background-white px-md rounded-lg mb-xxs">
        <router-link :to="{ name: 'proposals.list', query: { is_product_proposal: '0', status: 'proposed', connection_id: connection.id }  }">
          <div class="columns is-mobile is-vcentered is-marginless">
            <div class="column"><p class="has-text-weight-bold">Proposed</p></div>
            <div class="column is-one-fifth">
              <div class="has-background-white-ter pa-xs rounded-lg has-text-weight-bold has-text-centered">
                <span v-if="!loading" :class="summary.proposed.textClass">{{ summary.proposed.count }}</span>
                <span v-else><b-icon icon="loading" custom-class="mdi-spin" size="is-small"></b-icon></span>
              </div>
            </div>
          </div>
        </router-link>
      </div>
      <!-- Revised -->
      <div class="has-background-white px-md rounded-lg mb-xxs">
        <router-link :to="{ name: 'proposals.list', query: { is_product_proposal: '0', status: 'revised', connection_id: connection.id }  }">
          <div class="columns is-mobile is-vcentered is-marginless">
            <div class="column"><p class="has-text-weight-bold">Revised</p></div>
            <div class="column is-one-fifth">
              <div class="has-background-white-ter pa-xs rounded-lg has-text-weight-bold has-text-centered">
                <span v-if="!loading" :class="summary.revised.textClass">{{ summary.revised.count }}</span>
                <span v-else><b-icon icon="loading" custom-class="mdi-spin" size="is-small"></b-icon></span>
              </div>
            </div>
          </div>
        </router-link>
      </div>
      <!-- Approved -->
      <!-- <div class="has-background-white px-md rounded-lg mb-xxs">
        <router-link :to="{ name: 'proposals.list', query: { is_product_proposal: '0', status: 'approved', connection_id: connection.id }  }">
          <div class="columns is-mobile is-vcentered is-marginless">
            <div class="column"><p class="has-text-weight-bold">Approved</p></div>
            <div class="column is-one-fifth">
              <div class="has-background-white-ter pa-xs rounded-lg has-text-weight-bold has-text-centered">
                <span v-if="!loading" :class="summary.approved.textClass">{{ summary.approved.count }}</span>
                <span v-else><b-icon icon="loading" custom-class="mdi-spin" size="is-small"></b-icon></span>
              </div>
            </div>
          </div>
        </router-link>
      </div> -->
      <!-- Completed -->
      <!-- <div class="has-background-white px-md rounded-lg mb-xxs">
        <router-link :to="{ name: 'proposals.list', query: { status: is_product_proposal: '0', 'completed', connection_id: connection.id }  }">
          <div class="columns is-mobile is-vcentered is-marginless">
            <div class="column"><p class="has-text-weight-bold">Completed</p></div>
            <div class="column is-one-fifth">
              <div class="has-background-white-ter pa-xs rounded-lg has-text-weight-bold has-text-centered">
                <span v-if="!loading" :class="summary.completed.textClass">{{ summary.completed.count }}</span>
                <span v-else><b-icon icon="loading" custom-class="mdi-spin" size="is-small"></b-icon></span>
              </div>
            </div>
          </div>
        </router-link>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['context']),
    loading () {
      return Boolean(this.loadingProposals)
    },
    summary () {
      const summary = {
        draft: { count: 0, textClass: null },
        proposed: { count: 0, textClass: null },
        revised: { count: 0, textClass: null },
        approved: { count: 0, textClass: null },
        completed: { count: 0, textClass: null }
      }
      if ('draft' in this.proposals) {
        summary.draft = { count: this.proposals.draft.count }
      }
      if ('proposed' in this.proposals) {
        summary.proposed = { count: this.proposals.proposed.count }
      }
      if ('revised' in this.proposals) {
        summary.revised = { count: this.proposals.revised.count, textClass: 'has-text-warning' }
      }
      if ('approved' in this.proposals) {
        summary.approved = { count: this.proposals.approved.count, textClass: 'has-text-success' }
      }
      if ('completed' in this.proposals) {
        summary.completed = { count: this.proposals.completed.count, textClass: 'has-text-success' }
      }
      return summary
    },
    productSummary () {
      const summary = {
        draft: { count: 0, textClass: null },
        proposed: { count: 0, textClass: null },
        revised: { count: 0, textClass: null },
        approved: { count: 0, textClass: null },
        completed: { count: 0, textClass: null }
      }
      if ('draft' in this.products) {
        summary.draft = { count: this.products.draft.count }
      }
      if ('proposed' in this.products) {
        summary.proposed = { count: this.products.proposed.count }
      }
      if ('revised' in this.products) {
        summary.revised = { count: this.products.revised.count, textClass: 'has-text-warning' }
      }
      if ('approved' in this.products) {
        summary.approved = { count: this.products.approved.count, textClass: 'has-text-success' }
      }
      if ('completed' in this.products) {
        summary.completed = { count: this.products.completed.count, textClass: 'has-text-success' }
      }
      return summary
    }
  },
  data () {
    return {
      loadingProposals: true,
      loadingProducts: true,
      proposals: {},
      products: {}
    }
  },
  methods: {
    loadProposalSummary () {
      this.loadingProposals = true
      const endpoint = this.context.route + '/proposals/summary/'
      const query = {
        params: {
          connection_id: this.connection.id,
          is_product_proposal: 0
        }
      }
      this.$http.get(endpoint, query).then(response => {
        this.proposals = this.$utils.keyBy(response.data, (el) => {
          return el.status
        })
        this.loadingProposals = false
      })
    },
    loadProductSummary () {
      this.loadingProducts = true
      const endpoint = this.context.route + '/proposals/summary/'
      const query = {
        params: {
          connection_id: this.connection.id,
          is_product_proposal: 1
        }
      }
      this.$http.get(endpoint, query).then(response => {
        this.products = this.$utils.keyBy(response.data, (el) => {
          return el.status
        })
        this.loadingProducts = false
      })
    },
    navigateToSubmit () {
      if ('merchandise_via_product_proposals' in this.connection.options) {
        this.$router.push({ name: 'merchandise.connection.submit', params: { connection: this.connection.id } })
      } else {
        this.$router.push({ name: 'catalog.submit.new', params: { connection: this.connection.id } })
      }
    }
  },
  mounted () {
    if ('merchandise_via_product_proposals' in this.connection.options) {
      this.loadProductSummary()
      this.loadProposalSummary()
    } else if ('merchandise_via_proposals' in this.connection.options) {
      this.loadProposalSummary()
    }
  },
  props: {
    connection: {
      type: Object,
      required: true
    }
  }
}
</script>
