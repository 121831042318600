<template>
  <div>
    <!-- Has Actions -->
    <div v-if="hasActions">
      <div class="columns" style="justify-content: flex-end;">
        <!-- Actions -->
        <div class="column is-narrow" v-for="(action, index) in primaryActions" :key="index">
          <b-button v-if="action.type === 'primary'" type="is-info" expanded :size="size" @click="$emit(action.event)">{{ action.label }}</b-button>
          <b-button v-else type="is-info is-light" outlined expanded :size="size" @click="$emit(action.event)">{{ action.label }}</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['context']),
    hasActions () {
      return (this.primaryActions.length > 0 || this.moreActions.length > 0)
    },
    primaryActions () {
      const actions = []
      switch (this.proposal.status) {
        case 'draft':
          actions.push({ label: 'Submit', event: 'action:propose', type: 'primary' })
          actions.push({ label: 'Archive', event: 'action:archive', type: 'secondary' })
          break
        case 'revised':
          actions.push({ label: 'Resend', event: 'action:propose', type: 'primary' })
          actions.push({ label: 'Archive', event: 'action:archive', type: 'secondary' })
          break
        case 'proposed':
          actions.push({ label: 'Archive', event: 'action:archive', type: 'secondary' })
          break
      }
      return actions
    },
    moreActions () {
      const actions = []
      actions.push({ label: 'Export', event: 'action:export', type: 'secondary' })
      return actions
    }
  },
  props: {
    proposal: {
      type: Object,
      required: true
    },
    size: {
      type: String,
      default: 'is-medium'
    }
  }
}
</script>
