<template>
<div>
  <div class="mb-sm">
    <!-- Intro -->
    <div class="mb-lg">
      <p class="has-text-weight-bold has-text-info has-text-centered">Step 1 of 2: Import Products</p>
    </div>
    <div>
      <div v-if="!loadingConnection && connection !== null" class="mt-lg">
        <!-- Proposal Type -->
        <div class="mb-lg">
          <FieldLabel label="Purpose" class="mb-md" />
          <b-field>
            <b-radio v-model="proposalType" native-value="new_product">I want to submit new products for approval.</b-radio>
          </b-field>
        </div>
        <!-- Product Source -->
        <div class="mb-lg">
          <FieldLabel label="Product Source" class="mb-md" />
          <b-field>
            <b-radio v-model="proposalSource" native-value="template">I want to use a file template.</b-radio>
          </b-field>
          <!-- <b-field>
            <b-radio v-model="proposalSource" native-value="google" disabled>I want to use my Google Product Feed (coming soon!)</b-radio>
          </b-field>
          <b-field>
            <b-radio v-model="proposalSource" native-value="shopify" disabled>I want to import a Shopify collection (coming soon!)</b-radio>
          </b-field> -->
        </div>
        <!-- Template -->
        <div v-if="proposalSource === 'template'" class="mb-lg">
          <b-field label="Product Import Template">
            <TemplateSelect direction="import" datatype="products" v-model="template" size="is-medium" />
          </b-field>
        </div>
        <!-- Sample File -->
        <div v-if="template && template.sample_location" class="mb-lg">
          <b-message type="is-info">
            <div class="columns is-vcentered is-mobile">
              <div class="column">
                <p class="is-size-7">
                  <span class="has-text-weight-bold is-alert">Note: </span>Required file headers and guidelines are available in the sample template.
                </p>
              </div>
              <div class="column is-narrow">
                <a v-if="template.sample_location"
                  :href="template.sample_location"
                  class="button is-info is-small"
                  target="_blank"
                  expanded
                  >Download Template & Specs</a>
              </div>
            </div>
          </b-message>
        </div>
        <!-- File -->
        <div class="mb-lg">
          <CoreUpload @file:uploaded="updateTempfile" @file:reset="resetTempfile" :showLabel='true' />
        </div>
      </div>
      <div v-else>
        <loading message="Loading connection details..." />
      </div>
    </div>
    <!-- Navigate -->
    <div class="pt-lg">
      <div class="columns is-centered">
        <div class="column">
          <b-button
            type="is-light rounded-lg"
            size="is-medium"
            @click="goBack"
            expanded
            ><b-icon icon="arrow-left"></b-icon>
          </b-button>
        </div>
        <div class="column">
          <b-button
            type="is-primary rounded-lg"
            size="is-medium"
            expanded
            :loading="saving"
            :disabled="!ready || saving"
            @click="goForward"
          >Begin Import</b-button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import TemplateSelect from '@/components/Models/Template/TemplateSelect'
import CoreUpload from '@/components/CoreUpload'

export default {
  components: {
    TemplateSelect,
    CoreUpload
  },
  computed: {
    ...mapGetters(['app', 'context']),
    ready () {
      return (this.connection && this.template && this.tempfile)
    }
  },
  data () {
    return {
      loadingConnection: true,
      connection: null,
      proposalType: 'new_product',
      proposalSource: 'template',
      tempfile: null,
      proposal: null,
      feedQueue: null,
      saving: false,
      buttonLabel: 'Upload SKU List',
      errors: {},
      submitUpdatedImagesRetailers: ['z-gallerie', 'z-gallerie-uat'],
      toggleAdvancedOptions: false,
      setAutoSkuUpdate: false,
      template: null
    }
  },
  methods: {
    loadConnection () {
      this.loadingConnection = true
      const endpoint = this.context.route + '/connections/' + this.$route.params.connection + '/'
      this.$http.get(endpoint).then(response => {
        this.connection = response.data
        this.loadingConnection = false
      })
    },
    async goForward () {
      this.saving = true
      try {
        await this.createFeedQueue()
        await this.runFeedQueue()
        this.$router.replace({ name: 'merchandise.connection.submit', query: { step: 'Progress', feed_id: this.feedQueue.id } })
        this.$emit('navigate', 'Progress')
      } catch (error) {
        this.$buefy.toast.open({ type: 'is-danger', message: 'An unexpected error occured!' })
      }
      this.saving = false
    },
    goBack () {
      this.$router.push({ name: 'catalog' })
    },
    createProposal () {
      const endpoint = this.context.route + '/proposals/'
      const body = {
        connection: {
          id: this.connection.id
        },
        name: this.context.partner.name + ' - ' + this.$dates.utc().local().format('MMM D, YYYY @ h:mm'),
        source: 'console',
        proposal_type: this.proposalType
      }
      return this.$http.post(endpoint, body).then(response => {
        this.$emit('proposal:created', response.data)
        this.proposal = response.data
      })
    },
    createFeedQueue () {
      const endpoint = this.context.route + '/feed-queue/'
      const body = {
        direction: 'import',
        data_type: 'products',
        source: 'console',
        behavior: 'merge',
        template: {
          id: this.template.id
        },
        info: {
          proposal_feed_purpose: 'combined',
          send_proposal_to_connection_ids: [this.connection.id],
          allow_identifier_updates: this.setAutoSkuUpdate ? 1 : 0
        },
        file: {
          tempfile: {
            id: this.tempfile.id
          }
        }
      }
      return this.$http.post(endpoint + 'enqueue/', body).then(response => {
        this.$emit('feed:queued', response.data)
        this.feedQueue = response.data
      })
    },
    runFeedQueue () {
      const endpoint = this.context.route + '/feed-queue/' + this.feedQueue.id + '/run/'
      return this.$http.put(endpoint)
    },
    updateTempfile (file, response) {
      this.tempfile = response
    },
    resetTempfile () {
      this.tempfile = null
    }
  },
  mounted () {
    this.loadConnection()
  }
}
</script>
