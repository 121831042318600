<template>
  <form @submit.prevent="save">
    <div class="columns">
      <div class="column">
        <div class="has-background-white rounded-lg">
          <!-- Loaded -->
          <div v-if="preferences !== null" class="pa-lg pb-xl">
            <div class="mb-lg">
              <p class="has-text-weight-bold mb-xs">Onboarding Feature Flags (Staff Only)</p>
            </div>
            <div class="mb-lg">
              <div class="columns">
                <div class="column is-half">
                  <b-field v-for="(feature, index) in availableFeatures" :key="index">
                    <b-checkbox v-model="enabledFeatures" :native-value="feature.code" @input="isDirty = 1">{{ feature.name }}</b-checkbox>
                  </b-field>
                </div>
              </div>
            </div>
          </div>
          <div class="border-t">
            <div class="columns is-vcentered is-marginless">
              <div class="column is-hidden-mobile">
                <div class="px-sm has-text-grey-light">
                  <i v-if="isDirty">Click 'Save Features' to save your changes</i>
                </div>
              </div>
              <div class="column is-narrow">
                <div class="px-sm">
                  <b-button
                    native-type="submit"
                    type="is-primary rounded-lg"
                    :disabled="!ready || saving || !isDirty"
                  >Save Features</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['app', 'context']),
    ready () {
      return (this.isDirty !== null)
    }
  },
  data () {
    return {
      availableFeatures: [],
      enabledFeatures: [],
      isDirty: false,
      errors: {},
      saving: false
    }
  },
  methods: {
    save () {
      this.saving = true
      const endpoint = this.context.route + '/retailer-preferences/'
      const json = []
      this.availableFeatures.forEach(available => {
        if (this.enabledFeatures.includes(available.code)) {
          const enabledOption = this.$utils.find(available.options, ['code', 'enabled'])
          json.push({
            preference: { id: available.id },
            option: { id: enabledOption.id }
          })
        } else {
          const disabledOption = this.$utils.find(available.options, ['code', 'disabled'])
          json.push({
            preference: { id: available.id },
            option: { id: disabledOption.id }
          })
        }
      })
      this.$http.post(endpoint, json).then(response => {
        this.isDirty = false
        this.$buefy.toast.open({ type: 'is-success', message: 'Features updated!' })
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          this.errors = error.response.data
        }
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    this.preferences.forEach(preference => {
      if (preference.code.match(/^feature_.*$/g)) {
        this.availableFeatures.push(preference)
        if (preference.retailer_preference && preference.retailer_preference.value === '1') {
          this.enabledFeatures.push(preference.code)
        }
      }
    })
  },
  props: ['preferences']
}
</script>
