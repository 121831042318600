<template>
<div>
  <div class="has-background-white rounded-lg">
    <div class="columns is-vcentered">
      <div class="column px-lg">
        <b-button
          type="is-primary rounded-lg"
          class="is-pulled-right px-md"
          :loading="loading"
          @click="showCreateModal=true"
        >Create
        </b-button>
      </div>
    </div>
    <div class="border-t" />
    <!-- Results -->
    <div>
      <b-table
        :data="results"
        :mobile-cards="false"
        :loading="loading"
        hoverable
        class="is-size-7">
        <template>
          <!-- ID # -->
          <b-table-column
            label="ID"
            cell-class="has-text-grey-light"
            width="64"
            centered
            v-slot="props">
            {{props.row.id}}
          </b-table-column>
          <!-- Name -->
          <b-table-column
            label="Name"
            header-class="nowrap"
            cell-class="nowrap"
            v-slot="props">
            <div>
              <router-link class="has-text-info has-text-weight-bold"
                :to="{
                  name: 'ruleset.rulegroup',
                  params: {
                    id: props.row.id,
                    ruleset: ruleSetId
                  }
                }">
                {{props.row.name}}
              </router-link>
            </div>
          </b-table-column>
          <!-- Position -->
          <b-table-column
            label="Position"
            centered
            width="128"
            v-slot="props">
            {{ props.row.position }}
          </b-table-column>
          <!-- Last Updated -->
          <b-table-column
            label="Updated At"
            width="128"
            centered
            v-slot="props">
            {{ $dates.utc(props.row.updated_at).local().format('MMM DD, YYYY') }}
          </b-table-column>
        </template>
        <template #empty>
          <div class="py-xxl has-text-grey has-text-centered">
            {{ (loading) ? "Loading data..." : 'Rule groups are not yet added to this ruleset!' }}
          </div>
        </template>
      </b-table>
    </div>
  </div>
  <div class="mt-md">
    <b-pagination
      :total="total"
      :current.sync="currentPage"
      :rounded="true"
      :per-page="perPage"
      @change="paginate">
    </b-pagination>
  </div>
  <!-- Create rule group -->
  <b-modal
    v-if="showCreateModal"
    has-modal-card
    :active="showCreateModal"
    :can-cancel="['escape', 'x']"
    @close="showCreateModal=false"
    ><CreateRuleGroup @reload:ruleGroups="reload"/>
  </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Filterable from '@/mixins/Filterable'
import CreateRuleGroup from '@/components/Models/RuleGroup/Modals/Create'
export default {
  components: {
    CreateRuleGroup
  },
  computed: {
    ...mapGetters(['api', 'context']),
    endpoint () {
      return this.api.baseUrl + '/v1/rule-groups/'
    },
    alwaysAttach () {
      return {
        ruleset_id: this.$route.params.id
      }
    },
    ruleSetId () {
      return this.$route.params.id
    }
  },
  data () {
    return {
      key: 'ruleGroups',
      orderBy: 'position',
      loading: true,
      showCreateModal: false
    }
  },
  methods: {
    reload () {
      this.load()
    }
  },
  mixins: [Filterable],
  mounted () {
    this.load()
  },
  props: {
  }
}
</script>
