<template>
  <div>
    <!-- Ruleset Selector -->
    <div class="has-background-white rounded-md pa-md mb-md">
      <div class="columns is-align-items-center">
        <div class="column is-one-quarter">
          <b-select placeholder="Select Rules" v-model="selectedRulesetId" expanded>
            <option v-for="ruleset in rulesets" :value="ruleset.id" :key="ruleset.id">{{ ruleset.name }} (ID:{{ruleset.id }})</option>
          </b-select>
        </div>
        <div class="column">
          <b-select placeholder="Product Attributes (All Variants)" v-model="selectedVariant" expanded>
            <option :value="null">Product Attributes (All Variants)</option>
            <option v-for="variant in product.variants" :value="variant" :key="variant.id">{{ variant.name }} (SKU: {{variant.identifier }})</option>
          </b-select>
        </div>
        <div class="column is-narrow">
          <b-button type="is-info" class="px-xl" @click="saveChanges" :disabled="!ready" :loading="saving">Save</b-button>
        </div>
      </div>
    </div>
    <!-- General Product Attributes -->
    <div class="has-background-white rounded-md pa-lg mb-md is-size-7">
      <!-- Supplier Name -->
      <div v-if="context.isMerchant()" class="mb-sm">
        <div class="columns">
          <div class="column is-one-fifth has-text-weight-bold">
            <p class="has-text-weight-bold">Supplier</p>
          </div>
          <div class="column">{{ product.brand.name }}</div>
        </div>
      </div>
      <!-- Merchant Name -->
      <div v-if="context.isSupplier()" class="mb-sm">
        <div class="columns">
          <div class="column is-one-fifth has-text-weight-bold">
            <p class="has-text-weight-bold">Merchant</p>
          </div>
          <div class="column">{{ proposal.retailer.name }}</div>
        </div>
      </div>
      <!-- Department -->
      <div class="mb-sm">
        <div class="columns">
          <div class="column is-one-fifth has-text-weight-bold">
            <p class="has-text-weight-bold">Department</p>
          </div>
          <div class="column">{{ proposal.department.name }}</div>
        </div>
      </div>
      <!-- Product Name -->
      <div class="mb-sm">
        <div class="columns">
          <div class="column is-one-fifth has-text-weight-bold">
            <p class="has-text-weight-bold">Product Name</p>
          </div>
          <div class="column">{{ product.parent_name }}</div>
        </div>
      </div>
      <!-- Product Identifier -->
      <div class="mb-sm">
        <div class="columns">
          <div class="column is-one-fifth has-text-weight-bold">
            <p class="has-text-weight-bold">Product Identifier</p>
          </div>
          <div class="column">{{ product.parent_identifier }}</div>
        </div>
      </div>
      <!-- Return Reason -->
      <div v-if="proposal.return_reason" class="mb-sm">
        <div class="columns">
          <div class="column is-one-fifth has-text-weight-bold">
            <p class="has-text-weight-bold">Return Reason</p>
          </div>
          <div class="column">{{ proposal.return_reason.return_reason }}</div>
        </div>
      </div>
    </div>
    <!-- Media -->
    <div>
      <Media :product="product" :proposal="proposal" :ruleset="ruleset" :variant="selectedVariant" />
    </div>
    <!-- Rule Group Loop -->
    <div v-for="(attributeGroup, index) in attributeGroups" :key="index" class="has-background-white rounded-md pa-lg mb-md is-size-7">
      <AttributeValueEditor
        v-for="attribute in attributeGroup.attributes"
        :key="attribute.rule.id"
        :attribute="attribute"
        @attribute:update="trackChanges"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AttributeValueEditor from '@/components/Models/Product/AttributeValueEditor'
import Media from './Media'

export default {
  components: {
    AttributeValueEditor,
    Media
  },
  computed: {
    ...mapGetters(['app', 'context']),
    department () {
      return this.proposal.department
    },
    rulesets () {
      const rulesets = []
      rulesets.push({
        id: this.proposal.department.brand_ruleset.id,
        name: 'Supplier Rules'
      }, {
        id: this.proposal.department.retailer_ruleset.id,
        name: 'Retailer Rules'
      })
      return rulesets
    },
    json () {
      if (Object.keys(this.attributeChanges).length === 0) return {}
      const attributes = []
      Object.keys(this.attributeChanges).forEach(key => {
        attributes.push({
          attribute: {
            code: key
          },
          delete: (this.attributeChanges[key] === null || this.attributeChanges[key] === '') ? 1 : 0,
          value: this.attributeChanges[key]
        })
      })
      return { attributes }
    },
    ready () {
      return Boolean(!this.saving && Object.keys(this.attributeChanges).length)
    }
  },
  data () {
    return {
      selectedRulesetId: null,
      selectedVariant: null,
      loadingRuleset: true,
      ruleset: null,
      attributeGroups: [],
      attributeChanges: {},
      hiddenAttributes: ['parent_name', 'parent_identifier', 'cost', 'wholesale_cost', 'msrp'],
      saving: false,
      errors: {}
    }
  },
  methods: {
    loadRuleset () {
      this.loadingRuleset = true
      this.attributeChanges = {}
      const endpoint = this.context.route + '/connections/' + this.proposal.connection.id + '/rulesets/' + this.selectedRulesetId + '/'
      this.$http.get(endpoint).then(response => {
        this.attributeGroups = []
        this.ruleset = response.data
        this.loadAttributeGroups()
        this.loadingRuleset = false
      })
    },
    loadAttributeGroups () {
      if (!this.ruleset) return []
      if (Object.keys(this.product.attributes).length === 0 && this.product.variants.length === 1) this.selectedVariant = this.product.variants[0]
      this.attributeGroups = []
      this.ruleset.rule_groups.forEach(ruleGroup => {
        const group = {
          id: ruleGroup.id,
          name: ruleGroup.name,
          attributes: []
        }
        // if a variant is selected, build attributes off variant rules
        if (this.selectedVariant) {
          ruleGroup.rules.forEach(rule => {
            if (rule.target === 'variant' && !this.hiddenAttributes.includes(rule.attribute.code)) {
              group.attributes.push({
                rule: rule,
                value: rule.attribute.code in this.selectedVariant.attributes
                  ? this.selectedVariant.attributes[rule.attribute.code]
                  : null
              })
            }
          })
        // otherwise, build attributes off product rules
        } else {
          ruleGroup.rules.forEach(rule => {
            if (rule.target === 'product') {
              group.attributes.push({
                rule: rule,
                value: rule.attribute.code in this.product.attributes
                  ? this.product.attributes[rule.attribute.code]
                  : null
              })
            }
          })
        }
        if (group.attributes.length) {
          this.attributeGroups.push(group)
        }
      })
    },
    trackChanges (payload) {
      this.$set(this.attributeChanges, payload.attribute.code, payload.value)
    },
    saveChanges () {
      this.saving = true
      const endpoint = (this.selectedVariant)
        ? this.context.route + '/variants/' + this.selectedVariant.id + '/'
        : this.context.route + '/products/' + this.product.id + '/'
      this.$http.patch(endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Product saved!' })
        this.$emit('proposal:saved')
        this.saving = false
        this.attributeChanges = {}
        this.errors = {}
      })
    }
  },
  mounted () {
    this.selectedRulesetId = this.proposal.department.brand_ruleset.id
  },
  props: {
    proposal: {
      type: Object,
      required: true
    },
    product: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    selectedRulesetId (value) {
      if (value) {
        this.loadRuleset()
      }
    },
    selectedVariant (value) {
      this.loadAttributeGroups()
      this.attributeChanges = {}
    },
    product (value) {
      if (value) this.loadRuleset()
    }
  }
}
</script>
