<template>
  <div>
    <div class="modal-card">
      <section class="modal-card-body">
        <b-message type="is-info">
          <div>
            <p class="has-text-weight-bold is-size-5">Add Tags</p>
          </div>
        </b-message>
        <div class="pb-xxl">
          <form>
            <div class="columns">
              <div class="column">
                <b-field>
                  <b-autocomplete
                    v-model="tag"
                    placeholder="Add a new tag..."
                    :keep-first="false"
                    :open-on-focus="false"
                    :data="filteredTags"
                    field="name"
                    @keyup.enter.native="saveTags"
                    @select="option => selectTag(option)"
                    :clearable="false"
                  />
                </b-field>
              </div>
              <div class="column is-narrow">
                <b-button @click="saveTags">Add Tag</b-button>
              </div>
            </div>
          </form>
        </div>
      </section>
      <footer class="modal-card-foot">
        <b-button @click="$parent.close()">Close</b-button>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['context']),
    loading () {
      return Boolean(this.loadingUsers || this.loadingTags)
    },
    filteredTags () {
      if (this.tags.length === 0) return []
      return this.tags.filter((tag) => {
        return tag.name
          .toString()
          .toLowerCase()
          .indexOf(this.tag.toLowerCase()) >= 0
      })
    }
  },
  data () {
    return {
      loadingTags: true,
      tags: [],
      appliedTags: [],
      tag: '',
      isDirty: false,
      saving: false,
      errors: {}
    }
  },
  methods: {
    loadTags () {
      this.loadingTags = true
      const endpoint = this.context.route + '/tags/'
      const query = {
        params: {
          pagination: 0,
          content_type: 'proposal',
          order_by: 'name'
        }
      }
      this.$http.get(endpoint, query).then(response => {
        this.tags = response.data.results
        this.loadingTags = false
      })
    },
    selectTag (option) {
      this.tag = option.name
      this.saveTags()
    },
    saveTags (value) {
      this.saving = true
      const endpoint = this.context.route + '/proposals/bulk-add-tags/'
      const payload = {
        ids: this.proposals.map((proposal) => proposal.id),
        object_tags: [this.tag]
      }
      this.$http.post(endpoint, payload).then(response => {
        this.appliedTags.push({
          id: response.data.id,
          tag: response.data.name
        })
        this.tag = ''
        this.$buefy.toast.open({ type: 'is-success', message: 'Tags saved!' })
        this.$emit('tags:saved')
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    this.loadTags()
  },
  props: {
    proposals: {
      type: Array,
      required: true
    }
  }
}
</script>
