<template>
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="mb-md">
        <div class="columns is-vcentered">
          <div class="column is-one-third">
            <breadcrumbs :history="breadcrumbs.history" :current="breadcrumbs.current" />
          </div>
          <div class="column is-one-third">
            <h1 class="has-text-centered is-size-4">Merchandise</h1>
          </div>
        </div>
      </div>
      <!-- Connections -->
      <div v-if="!loading" class="my-lg">
        <p class="has-text-centered has-text-grey-light mb-lg pt-xl">Select a merchant partner.</p>
        <div class="columns is-centered is-multiline">
          <div v-for="connection in connections" :key="connection.id" class="column is-one-third">
            <router-link :to="{ name: (false) ? 'merchandise.connection.submit' : 'merchandise.connection', params: { connection: connection.id } }">
              <div class="has-background-white rounded-md pa-md">
                <div class="has-text-centered">
                  <div>
                    <img v-if="context.isSupplier()" :src="connection.retailer.logo_url" style="height:60px" height="60" />
                    <img v-else :src="connection.brand.logo_url" width="180" height="60"  />
                  </div>
                  <p class="has-text-centered has-text-weight-bold">
                    <span v-if="context.isSupplier()">{{ connection.retailer.name }}</span>
                    <span v-else>{{ connection.brand.name }}</span>
                  </p>
                  <div class="has-text-grey-light is-size-7">
                    <span v-if="connection.retailer_identifier">Connection #{{ connection.retailer_identifier }}</span>
                    <span v-else>Connection #{{ connection.id }}</span>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <!-- Loading State -->
      <div v-else class="py-lg has-text-centered has-text-grey-light">
        Checking connections...
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['api', 'context']),
    breadcrumbs () {
      return {
        current: (this.loading) ? 'Loading...' : 'Merchandise',
        history: [
          { label: 'Catalog', name: this.context.isSupplier() ? 'catalog' : 'catalog.index' }
        ]
      }
    },
    loading () {
      return Boolean(this.loadingConnections)
    }
  },
  data () {
    return {
      loadingConnections: true,
      connections: [],
      focusConnectionId: null,
      connection: null
    }
  },
  methods: {
    loadConnections () {
      this.loadingConnections = true
      const endpoint = this.context.route + '/connections/'
      const query = {
        params: {
          status: 'active,onboarding'
        }
      }
      this.$http.get(endpoint, query).then(response => {
        this.connections = response.data.results
        if (this.connections.length === 1) {
          this.$router.push({ name: 'merchandise.connection', params: { connection: this.connections[0].id } })
        } else {
          this.loadingConnections = false
        }
      })
    }
  },
  mounted () {
    this.loadConnections()
  }
}
</script>
