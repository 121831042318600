<template>
  <div>
    <div class="modal-card">
      <section class="modal-card-body">
        <b-message type="is-info">
          <p class="has-text-weight-bold is-size-5">Archive Product Proposal?</p>
        </b-message>
        <p class="py-xl has-text-centered">
         Are you sure you want to archive this proposal?
          This proposal will be canceled and this action cannot be undone.
        </p>
      </section>
      <footer class="modal-card-foot space-between has-background-white" >
        <b-button @click="$parent.close()">Close</b-button>
        <b-button
          type="is-danger"
          :disabled="saving"
          :loading="saving"
          @click="save">Archive</b-button>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['context'])
  },
  data () {
    return {
      saving: false,
      errors: {}
    }
  },
  methods: {
    save () {
      const endpoint = this.context.route + '/proposals/' + this.proposal.id + '/'
      const body = {
        status: 'archived'
      }
      this.$http.patch(endpoint, body).then(response => {
        this.$buefy.toast.open({ type: 'is-info', message: 'Proposal Archived!' })
        this.$emit('proposal:archived')
        this.$parent.close()
      }).finally(() => {
        this.saving = false
      })
    }
  },
  props: {
    proposal: {
      type: Object
    }
  }
}
</script>
