// import vue
import Vue from 'vue'

// import key plugins/packages
import HttpPlugin from '@/plugins/http.js'
import DatesPlugin from '@/plugins/dates.js'
import NumbersPlugin from '@/plugins/numbers.js'
import UtilsPlugin from '@/plugins/utils.js'

// import global app components
import Breadcrumbs from './components/Global/Breadcrumbs'
import LoadingIndicator from './components/Global/LoadingIndicator'
import FieldLabel from './components/Global/FieldLabel'
import Currency from './components/Global/Currency'
import CurrencyAttribute from './components/Global/CurrencyAttribute'
import Percentage from './components/Global/Percentage'
import DateDisplay from './components/Global/DateDisplay'
import DownloadFile from './components/Global/DownloadFile'
import CurrencyLocale from '@/components/Global/CurrencyLocale'

// import global mixins
// import PageTitleMixin from './mixins/PageTitle'

// import store and router (and sync)
import router from '@/router'
import store from '@/store'
import { sync } from 'vuex-router-sync'
import './filters'

// import 3rd party components & directives
import Buefy from 'buefy'
import Croppa from 'vue-croppa'
import VueClipboard from 'vue-clipboard2'
import JsonCSV from 'vue-json-csv'

// import app
import App from '@/App.vue'

// import 3rd party stylesheets
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import 'vue-croppa/dist/vue-croppa.css'

// import app stylesheets
import './assets/css/vendors.scss'
import './assets/css/main.scss'

// install plugins
Vue.use(HttpPlugin)
Vue.use(DatesPlugin)
Vue.use(NumbersPlugin)
Vue.use(UtilsPlugin)

// install 3p libraries
Vue.use(Buefy, {
  defaultToastPosition: 'is-bottom'
})
Vue.use(Croppa)
Vue.use(VueClipboard)
Vue.use(JsonCSV)

// register global components
Vue.component('breadcrumbs', Breadcrumbs)
Vue.component('loading', LoadingIndicator)
Vue.component('downloadCsv', JsonCSV)
Vue.component('FieldLabel', FieldLabel)
Vue.component('Currency', Currency)
Vue.component('CurrencyAttribute', CurrencyAttribute)
Vue.component('Percentage', Percentage)
Vue.component('Date', DateDisplay)
Vue.component('DownloadFileAs', DownloadFile)
Vue.component('CurrencyLocale', CurrencyLocale)

// register global mixin
// Vue.mixin(PageTitleMixin)

// sync router with the store
sync(store, router)

// disable dev tools tooltip
Vue.config.productionTip = false

// usage: v-permission="'Create User'"
// Create User is the permission, which can be read as binding.value
Vue.directive('permission', {
  bind (el, binding) {
    const { getters } = store
    const { permissions } = getters && getters.auth && getters.auth.user
    if (permissions && permissions.length && !permissions.some(el => el.name === binding.value)) {
      el.style.display = 'none'
    }
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
