<template>
  <div>
    <!-- Basics -->
    <div class="has-background-white rounded-md mb-md">
      <!-- Name -->
      <div class="pa-md">
        <div class="columns">
          <div class="column is-one-fifth">
            <p class="has-text-weight-bold is-size-7">Proposal Name</p>
            <p class="is-size-7 has-text-grey-light">By default, proposal names inherit the product name, but you may customize it to improve searchability.</p>
          </div>
          <div class="column is-half">
            <b-field
              :type="('name' in errors) ? 'is-danger' : ''"
              :message="('name' in errors) ? errors['name'][0] : ''">
              <b-input v-model="proposalName" expanded @input="isDirty = true"/>
            </b-field>
          </div>
        </div>
      </div>
      <!-- Owner -->
      <div class="pa-md">
        <div class="columns">
          <div class="column is-one-fifth">
            <p class="has-text-weight-bold is-size-7">Proposal Owner</p>
            <p class="is-size-7 has-text-grey-light">Change the owner of this proposal.</p>
          </div>
          <div class="column is-half">
            <b-field
              :type="('name' in errors) ? 'is-danger' : ''"
              :message="('name' in errors) ? errors['name'][0] : ''">
              <b-select v-model="proposalOwner" placeholder="Select Owner..." expanded @input="isDirty = true">
                <option v-for="user in users" :key="user.id" :value="user.id">{{ user.first_name }} {{ user.last_name }}</option>
              </b-select>
            </b-field>
          </div>
        </div>
      </div>
      <!-- Save -->
      <div class="border-t pa-md has-text-right">
        <b-button type="is-info" @click="saveProposal" :disabled="saving || !isDirty" :loading="saving">Save</b-button>
      </div>
    </div>
    <!-- Tags -->
    <div class="has-background-white pa-md rounded-md mb-md">
      <div class="columns">
        <div class="column is-one-fifth">
          <p class="has-text-weight-bold is-size-7">
            Proposal Tags
          </p>
        </div>
        <div class="column is-half">
          <div>
            <b-taglist>
              <b-tag v-for="tag in proposal.tags" :key="tag.id" type="is-info is-light" closable @close="removeTag(tag.id)">{{ tag.tag }}</b-tag>
            </b-taglist>
            <form>
              <b-field>
                <b-autocomplete
                  v-model="tag"
                  placeholder="Add a new tag..."
                  :keep-first="false"
                  :open-on-focus="false"
                  :data="filteredTags"
                  field="name"
                  @keyup.enter.native="addTag"
                  @select="option => selectTag(option)"
                  :clearable="false"
                />
              </b-field>
              <b-button @click="addTag">Add Tag</b-button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- Dates -->
    <div class="has-background-white pa-md rounded-md mb-md">
      <div class="columns is-multiline">
        <!-- Proposed -->
        <div class="column is-one-fifth">
          <p class="has-text-weight-bold is-size-7">Proposed</p>
        </div>
        <div class="column is-four-fifths">
          <p class="is-size-7">
            <span v-if="proposal.proposed_at !== null">{{ $dates.utc(proposal.proposed_at).local().format('MMM D, YYYY @ h:mm A')}}</span>
            <span v-else class="has-text-grey-light">--</span>
          </p>
        </div>
        <!-- Declined -->
        <template v-if="proposal.declined_at !== null">
          <div  class="column is-one-fifth">
            <p class="has-text-weight-bold is-size-7">Declined</p>
          </div>
          <div v-if="proposal.declined_at !== null" class="column is-four-fifths">
            <p class="has-text-weight-bold is-size-7">
              <span>{{ $dates.utc(proposal.declined_at).local().format('MMM D, YYYY @ h:mm A')}}</span>
            </p>
          </div>
        </template>
        <!-- Approved -->
        <div class="column is-one-fifth">
          <p class="has-text-weight-bold is-size-7">Approved</p>
        </div>
        <div class="column is-four-fifths">
          <p class="has-text-weight-bold is-size-7">
            <span v-if="proposal.approved_at !== null">{{ $dates.utc(proposal.approved_at).local().format('MMM D, YYYY @ h:mm A')}}</span>
            <span v-else class="has-text-grey-light">--</span>
          </p>
        </div>
        <!-- Pricing Approved -->
        <template v-if="'require_pricing_approval_on_proposals' in this.proposal.connection.options">
          <div class="column is-one-fifth">
            <p class="has-text-weight-bold is-size-7">Manager Approved</p>
          </div>
          <div class="column is-four-fifths">
            <p class="has-text-weight-bold is-size-7">
              <span v-if="proposal.pricing_approved_at !== null">{{ $dates.utc(proposal.pricing_approved_at).local().format('MMM D, YYYY @ h:mm A')}}</span>
              <span v-else class="has-text-grey-light">--</span>
            </p>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {
  },
  computed: {
    ...mapGetters(['context']),
    loading () {
      return Boolean(this.loadingUsers || this.loadingTags)
    },
    filteredTags () {
      if (this.tags.length === 0) return []
      return this.tags.filter((tag) => {
        return tag.name
          .toString()
          .toLowerCase()
          .indexOf(this.tag.toLowerCase()) >= 0
      })
    },
    json () {
      const json = {
        name: this.proposalName
      }
      if (this.proposalOwner !== this.proposal.sender.user.id) {
        if (
          (this.context.isSupplier() && 'brand' in this.proposal.sender) ||
          (this.context.isMerchant() && 'retailer' in this.proposal.sender)
        ) {
          json.sender = { user: { id: this.proposalOwner } }
        }
        if (
          (this.context.isSupplier() && 'brand' in this.proposal.recipient) ||
          (this.context.isMerchant() && 'retailer' in this.proposal.recipient)
        ) {
          json.recipient = { user_id: this.proposalOwner }
        }
      }
      return json
    }
  },
  data () {
    return {
      loadingUsers: true,
      loadingTags: true,
      tags: [],
      appliedTags: [],
      tag: '',
      users: [],
      proposalName: this.proposal.name,
      proposalOwner: null,
      isDirty: false,
      saving: false,
      errors: {},
      showViewReturnReasonModal: false
    }
  },
  methods: {
    saveProposal () {
      this.saving = true
      const endpoint = this.context.route + '/proposals/' + this.proposal.id + '/'
      this.$http.patch(endpoint, this.json).then(() => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Proposal saved!' })
        this.$emit('proposal:saved')
      }).finally(() => {
        this.saving = false
      })
    },
    selectTag (option) {
      this.tag = option.name
      this.addTag()
    },
    addTag (value) {
      this.saving = true
      const endpoint = this.context.route + '/proposals/' + this.proposal.id + '/tags/'
      const payload = {
        tag: this.tag,
        visibility_level: 'user'
      }
      this.$http.post(endpoint, payload).then(response => {
        this.appliedTags.push({
          id: response.data.id,
          tag: response.data.name
        })
        this.tag = ''
        this.$buefy.toast.open({ type: 'is-success', message: 'Tag added!' })
        this.$emit('proposal:saved')
      }).finally(() => {
        this.saving = false
      })
    },
    removeTag (id) {
      this.saving = true
      const endpoint = this.context.route + '/proposals/bulk-delete-tags/'
      const payload = {
        object_tag_item_ids: [id]
      }
      this.$http.post(endpoint, payload).then(response => {
        this.$buefy.toast.open({ type: 'is-info', message: 'Tag removed!' })
        this.$emit('proposal:saved')
      }).finally(() => {
        this.saving = false
      })
    },
    loadUsers () {
      this.loadingUsers = true
      const endpoint = this.context.route + '/users/'
      const query = {
        params: {
          pagination: 0,
          mini: 1,
          type: 'standard',
          order_by: 'last_name',
          status: 'active,invited'
        }
      }
      this.$http.get(endpoint, query).then(response => {
        this.users = response.data.results
        this.loadingUsers = false
      })
    },
    loadTags () {
      this.loadingTags = true
      const endpoint = this.context.route + '/tags/'
      const query = {
        params: {
          pagination: 0,
          content_type: 'proposal',
          order_by: 'name'
        }
      }
      this.$http.get(endpoint, query).then(response => {
        this.tags = response.data.results
        this.loadingTags = false
      })
    }
  },
  mounted () {
    this.loadUsers()
    this.loadTags()
    this.proposalOwner = (this.context.isMerchant()) ? this.proposal.recipient.user.id : this.proposal.sender.user.id
    this.proposal.tags.forEach(tag => {
      this.appliedTags.push({
        id: tag.id,
        tag: tag.tag
      })
    })
  },
  props: {
    proposal: {
      type: Object,
      required: true
    }
  }
}
</script>
