<template>
  <div>
    <b-field
      :type="validator.inputFieldClass"
      :message="validator.inputFieldMessage"
      expanded>
      <b-input
        type="textarea"
        size="is-small"
        v-model="currentValue"
        placeholder=""
        :disabled="!isEditable"
        :readonly="!isEditable"
        @input="announce"
        expanded />
    </b-field>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['context']),
    isEditable () {
      if (this.context.isMerchant()) {
        if (this.attribute.rule.attribute.editable_by === 'brand') return false
        return true
      }
      return true
    },
    validator () {
      const validator = {
        inputFieldClass: null,
        inputFieldMessage: null
      }
      if (this.attribute.rule.is_required === true && (this.currentValue === null || this.currentValue === '')) {
        return {
          inputFieldClass: 'is-danger',
          inputFieldMessage: 'A value for this field is required.'
        }
      }
      if (this.originalValue !== this.currentValue) {
        return {
          inputFieldClass: 'is-info',
          inputFieldMessage: null
        }
      }
      return validator
    }
  },
  data () {
    return {
      originalValue: null,
      currentValue: null
    }
  },
  methods: {
    announce () {
      this.$emit('input', {
        attribute: {
          code: this.attribute.rule.attribute.code
        },
        value: this.currentValue
      })
    }
  },
  mounted () {
    if (this.attribute.value) {
      this.originalValue = this.attribute.value.values[0]?.value
      this.currentValue = this.attribute.value.values[0]?.value
    }
  },
  props: {
    attribute: {
      type: Object,
      required: true
    }
  }
}
</script>
