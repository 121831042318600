<template>
  <div class="tags">
    <b-tag v-for="(tag, index) in tags" :key="index">{{ tag.tag }}</b-tag>
    <b-tag v-if="moreCounter">+ {{ moreCounter }} More</b-tag>
  </div>
</template>

<script>
export default {
  computed: {
    tags () {
      const display = []
      const maxDisplay = (this.proposal.tags.length > this.maxTags) ? this.maxTags : this.proposal.tags.length
      for (let index = 0; index < maxDisplay; index++) {
        display.push(this.proposal.tags[index])
      }
      return display
    },
    moreCounter () {
      const count = this.proposal.tags.length
      return (count > 2) ? this.proposal.tags.length - this.maxTags : null
    }
  },
  data () {
    return {
      maxTags: 2
    }
  },
  props: {
    proposal: {
      type: Object,
      required: true
    }
  }
}
</script>
