<template>
  <div>
    <div class="">
      <div class="columns">
        <div class="column is-half">
          <div class="mb-sm">
            <figure class="image border pa-xs">
              <img class="rounded-md" :src="attributeMedia[mediaIndex].url" />
            </figure>
          </div>
        </div>
        <div class="column is-half">
          <div class="columns is-mobile is-multiline is-variable is-1">
            <div v-for="(image, index) in attributeMedia" :key="image.id" class="column is-one-fifth">
              <a href="#" @click.prevent="focusImage(index)">
                <figure class="image border pa-xs" :class="(index === mediaIndex) ? 'has-border-primary' : ''">
                  <img class="rounded-md" :src="attributeMedia[index].url" />
                </figure>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['app', 'context']),
    attributeMedia () {
      const media = []
      for (const code in this.product.attributes) {
        if (code.match(/^media_image_\d$/g)) {
          media.push({
            id: this.product.attributes[code].values[0].id,
            url: this.product.attributes[code].values[0].value
          })
        }
      }
      // if no product images are found, search for variant images
      if (media.length === 0 && this.product.variants.length === 1) {
        for (const code in this.product.variants[0].attributes) {
          if (code.match(/^media_image_\d$/g)) {
            media.push({
              id: this.product.variants[0].attributes[code].values[0].id,
              url: this.product.variants[0].attributes[code].values[0].value
            })
          }
        }
      }
      return media
    },
    media () {
      const media = []
      if (this.product) {
        if (this.product.primary_media !== null && this.product.primary_media.media !== 'default') {
          media.push(this.product.primary_media)
        }
        this.product.media.forEach(image => {
          if (image.media !== 'default') {
            media.push(image)
          }
        })
      }
      return media
    }
  },
  data () {
    return {
      mediaIndex: 0
    }
  },
  methods: {
    focusImage (index) {
      this.mediaIndex = index
    },
    getImgUrl (value) {
      return this.media[value].url
    }
  },
  props: {
    proposal: {
      type: Object || null
    },
    product: {
      type: Object || null
    }
  }
}
</script>
