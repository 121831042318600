<template>
  <div class="is-size-7 has-text-danger has-text-weight-bold">
    {{ formattedCostString }}
  </div>
</template>

<script>
export default {
  computed: {
    symbol () {
      return ('unit' in this.product.pricing) ? this.product.pricing.unit.symbol : '$'
    },
    formattedCostString () {
      if ('cost' in this.product.pricing) {
        const high = this.product.pricing.cost.high
        const low = this.product.pricing.cost.low
        return (high === low)
          ? this.symbol + this.$numbers(this.product.pricing.cost.low).format({ mantissa: 2 })
          : this.symbol + this.$numbers(this.product.pricing.cost.low).format({ mantissa: 2 }) + ' - ' + this.symbol + this.$numbers(this.product.pricing.cost.high).format({ mantissa: 2 })
      }
      return null
    }
  },
  props: {
    product: {
      type: Object,
      required: true
    }
  }
}
</script>
