<template>
  <div class="columns">
    <div class="column is-one-fifth">
      <p class="has-text-weight-bold">
        {{ attribute.rule.name }}
        <span v-if="attribute.rule.is_required" class="has-text-danger">*</span>
      </p>
    </div>
    <div class="column">
      <template v-if="type === 'boolean'">
        <BooleanAttribute :attribute="attribute" @input="announce" />
      </template>
      <template v-else-if="type === 'money'">
        <MoneyAttribute :attribute="attribute" @input="announce" />
      </template>
      <template v-else-if="type === 'media'">
        <MediaAttribute :attribute="attribute" @input="announce" />
      </template>
      <template v-else-if="type === 'number'">
        <NumberAttribute :attribute="attribute" @input="announce" />
      </template>
      <template v-else-if="type === 'text'">
        <TextAttribute :attribute="attribute" @input="announce" />
      </template>
      <template v-else-if="type === 'string'">
        <StringAttribute :attribute="attribute" @input="announce" />
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BooleanAttribute from './BooleanAttribute'
import MediaAttribute from './MediaAttribute'
import MoneyAttribute from './MoneyAttribute'
import NumberAttribute from './NumberAttribute'
import StringAttribute from './StringAttribute'
import TextAttribute from './TextAttribute'

export default {
  components: {
    BooleanAttribute,
    MediaAttribute,
    MoneyAttribute,
    NumberAttribute,
    StringAttribute,
    TextAttribute
  },
  data () {
    return {

    }
  },
  computed: {
    ...mapGetters(['context']),
    type () {
      return this.attribute.rule.attribute.type
    }
  },
  methods: {
    announce (payload) {
      this.$emit('attribute:update', payload)
    }
  },
  props: {
    attribute: {
      type: Object
    }
  }
}
</script>
