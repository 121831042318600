<template>
  <b-tag :type="presenter.class">{{ presenter.label }}</b-tag>
</template>

<script>
export default {
  computed: {
    presenter () {
      let result = null
      if (this.proposal.products.length === 1 && this.proposal.products[0].departments.length === 1) {
        result = this.proposal.products[0].departments[0].status
      }
      if (result === 'passed') {
        return { class: 'is-success is-light', label: 'Passed' }
      }
      if (result === 'failed') {
        return { class: 'is-warning', label: 'Needs Review' }
      }
      return { class: 'is-info is-light', label: 'Processing' }
    }
  },
  props: {
    proposal: {
      type: Object,
      required: true
    }
  }
}
</script>
