<template>
  <div>
    <!-- Ruleset Selector -->
    <div class="mb-md">
      <div class="columns is-align-items-center">
        <div class="column is-one-quarter">
          <b-select placeholder="Select Rules" v-model="selectedRulesetId" expanded size="is-small">
            <option v-for="ruleset in rulesets" :value="ruleset.id" :key="ruleset.id">{{ ruleset.name }} (ID:{{ruleset.id }})</option>
          </b-select>
        </div>
        <div class="column">
          <b-select placeholder="Product Attributes" v-model="selectedVariant" expanded size="is-small">
            <option :value="null">Product Attributes (All Variants)</option>
            <option v-for="variant in product.variants" :value="variant" :key="variant.id">{{ variant.name }} (ID:{{variant.id }})</option>
          </b-select>
        </div>
      </div>
    </div>
    <!-- General Product Attributes -->
    <div class="mb-md is-size-7">
      <table class="table is-narrow is-striped border is-fullwidth is-size-7">
        <tbody>
          <tr v-if="context.isMerchant()">
            <td style="width:33%">Supplier</td>
            <td class="has-text-right">{{ product.brand.name }}</td>
          </tr>
          <tr v-if="context.isSupplier()">
            <td style="width:33%">Merchant</td>
            <td class="has-text-right">{{ proposal.retailer.name }}</td>
          </tr>
          <tr>
            <td>Department</td>
            <td class="has-text-right">{{ proposal.department.name }}</td>
          </tr>
          <tr>
            <td>Product Name</td>
            <td class="has-text-right">{{ product.parent_name }}</td>
          </tr>
          <tr>
            <td>Product Identifier</td>
            <td class="has-text-right">{{ product.parent_identifier }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Rule Group Loop -->
    <table v-for="(attributeGroup, index) in attributeGroups" :key="index" class="table is-narrow is-striped border is-fullwidth is-size-7">
      <tbody>
        <AttributeValueReader
          v-for="attribute in attributeGroup.attributes"
          :key="attribute.rule.id"
          :attribute="attribute"
        />
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AttributeValueReader from './AttributeValueReader'

export default {
  components: {
    AttributeValueReader
  },
  computed: {
    ...mapGetters(['app', 'context']),
    department () {
      return this.proposal.department
    },
    rulesets () {
      const rulesets = []
      rulesets.push({
        id: this.proposal.department.brand_ruleset.id,
        name: 'Supplier Rules'
      }, {
        id: this.proposal.department.retailer_ruleset.id,
        name: 'Retailer Rules'
      })
      return rulesets
    },
    json () {
      if (Object.keys(this.attributeChanges).length === 0) return {}
      const attributes = []
      Object.keys(this.attributeChanges).forEach(key => {
        attributes.push({
          attribute: {
            code: key
          },
          delete: (this.attributeChanges[key] === null || this.attributeChanges[key] === '') ? 1 : 0,
          value: this.attributeChanges[key]
        })
      })
      return { attributes }
    },
    ready () {
      return Boolean(!this.saving && Object.keys(this.attributeChanges).length)
    }
  },
  data () {
    return {
      selectedRulesetId: null,
      selectedVariant: null,
      loadingRuleset: true,
      ruleset: null,
      attributeGroups: [],
      hiddenAttributes: ['parent_name', 'parent_identifier', 'cost', 'wholesale_cost', 'msrp']
    }
  },
  methods: {
    loadRuleset () {
      this.loadingRuleset = true
      this.attributeChanges = {}
      const endpoint = this.context.route + '/connections/' + this.proposal.connection.id + '/rulesets/' + this.selectedRulesetId + '/'
      this.$http.get(endpoint).then(response => {
        this.attributeGroups = []
        this.ruleset = response.data
        this.loadAttributeGroups()
        this.loadingRuleset = false
      })
    },
    loadAttributeGroups () {
      if (!this.ruleset) return []
      this.attributeGroups = []
      this.ruleset.rule_groups.forEach(ruleGroup => {
        const group = {
          id: ruleGroup.id,
          name: ruleGroup.name,
          attributes: []
        }
        // if a variant is selected, build attributes off variant rules
        if (this.selectedVariant) {
          ruleGroup.rules.forEach(rule => {
            if (rule.target === 'variant' && !this.hiddenAttributes.includes(rule.attribute.code)) {
              group.attributes.push({
                rule: rule,
                value: rule.attribute.code in this.selectedVariant.attributes
                  ? this.selectedVariant.attributes[rule.attribute.code]
                  : null
              })
            }
          })
        // otherwise, build attributes off product rules
        } else {
          ruleGroup.rules.forEach(rule => {
            if (rule.target === 'product' && rule.attribute.type !== 'media') {
              group.attributes.push({
                rule: rule,
                value: rule.attribute.code in this.product.attributes
                  ? this.product.attributes[rule.attribute.code]
                  : null
              })
            }
          })
        }
        if (group.attributes.length) {
          this.attributeGroups.push(group)
        }
      })
    },
    trackChanges (payload) {
      this.$set(this.attributeChanges, payload.attribute.code, payload.value)
    },
    saveChanges () {
      this.saving = true
      const endpoint = (this.selectedVariant)
        ? this.context.route + '/variants/' + this.selectedVariant.id + '/'
        : this.context.route + '/products/' + this.product.id + '/'
      this.$http.patch(endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Product saved!' })
        this.$emit('product:updated')
        this.saving = false
        this.attributeChanges = {}
        this.errors = {}
      })
    }
  },
  mounted () {
    this.selectedRulesetId = this.proposal.department.brand_ruleset.id
  },
  props: {
    proposal: {
      type: Object,
      required: true
    },
    product: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    selectedRulesetId (value) {
      if (value) {
        this.loadRuleset()
      }
    },
    selectedVariant (value) {
      this.loadAttributeGroups()
    },
    product (value) {
      if (value) this.loadRuleset()
    }
  }
}
</script>
