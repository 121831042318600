<template>
  <b-tag :type="presenter.class">{{ presenter.label }}</b-tag>
</template>

<script>
export default {
  computed: {
    presenter () {
      if (this.proposal.status === 'proposed') {
        return { class: 'is-info is-light', label: 'Proposed' }
      }
      if (this.proposal.status === 'revised') {
        return { class: 'is-danger', label: 'Revised' }
      }
      if (this.proposal.status === 'approved') {
        return { class: 'is-info', label: 'Approved' }
      }
      if (this.proposal.status === 'pricing_approved') {
        return { class: 'is-info', label: 'Pricing Approved' }
      }
      if (this.proposal.status === 'completed' && this.proposal.acknowledged_at === null) {
        return { class: 'is-success', label: 'Ready to Publish' }
      }
      if (this.proposal.status === 'completed' && this.proposal.acknowledged_at !== null) {
        return { class: 'is-success is-light', label: 'Completed' }
      }
      if (this.proposal.status === 'declined') {
        return { class: 'is-danger is-light', label: 'Declined' }
      }
      if (this.proposal.status === 'archived') {
        return { class: 'is-light', label: 'Archived' }
      }
      // default = 'draft'
      return { class: 'has-text-grey', label: 'Draft' }
    }
  },
  props: {
    proposal: {
      type: Object,
      required: true
    }
  }
}
</script>
