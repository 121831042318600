<template>
  <div>
    <form @submit.prevent="save">
      <div class="modal-card">
        <section class="modal-card-body">
          <b-message type="is-info">
            <div>
              <p class="has-text-weight-bold is-size-5">Merge Products into Proposal</p>
            </div>
          </b-message>
          <div class="mb-lg">
            <p class="has-text-weight-bold mb-sm">Behavior</p>
            <b-field><b-radio v-model="method" native-value="new">Add products to a <b>new</b> proposal</b-radio></b-field>
            <b-field><b-radio v-model="method" native-value="existing">Add products to an <b>existing</b> proposal</b-radio></b-field>
          </div>
          <div v-if="method === 'new'" class="mb-lg">
            <!-- New Proposal Name -->
            <b-field
              label="New Proposal Name"
              :type="('name' in errors) ? 'is-danger' : ''"
              :message="('name' in errors) ? errors['name'][0] : ''"
              expanded>
              <b-input
                type="text"
                v-model="name"
                placeholder="Enter new proposal name..."
                size="is-medium"
              ></b-input>
            </b-field>
            <!-- New Proposal Onwer -->
            <b-field
              label="New Proposal Owner"
              :type="('owner_user_id' in errors) ? 'is-danger' : ''"
              :message="('owner_user_id' in errors) ? errors['owner_user_id'][0] : ''"
              expanded>
              <b-select v-model="proposalOwner" placeholder="Select Owner..." expanded size="is-medium">
                <option v-for="user in users" :key="user.id" :value="user.id">{{ user.first_name }} {{ user.last_name }}</option>
              </b-select>
            </b-field>
          </div>
        </section>
        <footer class="modal-card-foot space-between">
          <b-button @click="$parent.close()">Close</b-button>
          <b-button
            type="is-primary"
            native-type="submit"
            :loading="saving"
            :disabled="saving">
            Merge Products
          </b-button>
        </footer>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['context']),
    loading () {
      return Boolean(this.loadingUsers)
    },
    json () {
      return {
        name: null,
        owner_user_id: 1002,
        proposal_ids: []
      }
    }
  },
  data () {
    return {
      loadingUsers: true,
      users: [],
      method: 'new',
      name: null,
      proposalOwner: null,
      saving: false,
      errors: {}
    }
  },
  methods: {
    loadUsers () {
      this.loadingUsers = true
      const endpoint = this.context.route + '/users/'
      const query = {
        params: {
          pagination: 0,
          mini: 1,
          type: 'standard',
          order_by: 'last_name',
          status: 'active,invited'
        }
      }
      this.$http.get(endpoint, query).then(response => {
        this.users = response.data.results
        this.loadingUsers = false
      })
    },
    save () {
      this.saving = true
      const endpoint = this.context.route + '/proposals/merge/'
      const query = { ids: this.$utils.map(this.proposals, 'id').join(',') }
      const body = {
        name: this.name
      }
      this.$http.post(endpoint, body, { params: query }).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Proposals merged!' })
        this.$parent.close()
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    this.loadUsers()
  },
  props: {
    proposals: {
      type: Array,
      required: true
    }
  }
}
</script>
