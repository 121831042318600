<template>
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="mb-lg">
        <div class="columns is-vcentered">
          <div class="column is-one-third">
            <breadcrumbs :history="breadcrumbs.history" :current="breadcrumbs.current" />
          </div>
          <div class="column is-one-third">
            <h1 class="has-text-centered is-size-4">Proposals</h1>
          </div>
        </div>
      </div>
      <!-- Filters Toolbar -->
      <div class="has-background-white rounded-lg pa-md mb-xxs">
        <div class="columns">
          <div class="column">
            <proposal-filters :applied-filters="filters" @filter="filter" />
          </div>
          <div class="column is-narrow">
            <b-select v-model="orderBy" size="is-medium" expanded @input="sort">
              <option value="-id">Most Recent</option>
              <option value="id">Oldest</option>
            </b-select>
          </div>
        </div>
      </div>
      <!-- Actions Toolbar -->
      <div class="has-background-white rounded-lg px-md py-sm mb-xxs">
        <div class="columns is-vcentered is-mobile">
          <div class="column is-narrow">
            <b-dropdown :disabled="loading || selected.length === 0">
              <button class="button" slot="trigger" slot-scope="{ active }" :disabled="selected.length === 0">
                <span>Actions</span>
                <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
              </button>
              <template v-if="context.isSupplier()">
                <b-dropdown-item has-link><a href="#" @click.prevent="showWorkflowActionsModal = true">Archive Proposal</a></b-dropdown-item>
              </template>
              <template v-if="context.isMerchant()">
                <b-dropdown-item has-link><a href="#" @click.prevent class="has-text-grey-light">No Actions</a></b-dropdown-item>
              </template>
            </b-dropdown>
          </div>
          <div class="column">
            <div class="has-text-right is-size-7">
              <!-- Counts -->
              <p v-if="loading">Loading...</p>
              <p v-else>{{ total }} {{ (total === 1) ? 'proposal' : 'proposals' }} found.</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Results -->
      <div>
        <div class="mb-md">
          <b-table
            :data="results"
            :checked-rows.sync="selected"
            :mobile-cards="false"
            :loading="loading"
            hoverable
            checkable
            checkbox-position="left"
            class="is-size-7">
            <template>
              <!-- RC ID -->
              <b-table-column
                label="ID"
                width="64"
                v-slot="props">
                <router-link
                  class="has-text-info has-text-weight-bold"
                  :to="{ name: 'proposal.detail.summary', params: { id: props.row.id} }">
                  {{ props.row.id }}
                </router-link>
              </b-table-column>
              <!-- Supplier -->
              <b-table-column
                v-if="context.isMerchant()"
                label="Supplier"
                cell-class="nowrap"
                width="164"
                v-slot="props">
                {{ props.row.connection.brand.name }}
              </b-table-column>
              <!-- Merchant -->
              <b-table-column
                v-if="context.isSupplier()"
                label="Retailer"
                cell-class="nowrap"
                width="164"
                v-slot="props">
                {{ props.row.connection.retailer.name }}
              </b-table-column>
              <!-- Name -->
              <b-table-column
                label="Name"
                cell-class="nowrap"
                v-slot="props">
                <p>{{ $utils.truncate(props.row.name, { 'length': 64 }) }}</p>
                <p><TypePresenter :type="props.row.proposal_type" /></p>
              </b-table-column>
              <!-- Items Count -->
              <b-table-column
                label="Total Items"
                cell-class="nowrap"
                width="128"
                centered
                v-slot="props">
                {{ props.row.item_count }}
              </b-table-column>
              <!-- Proposed -->
              <b-table-column
                label="Proposed"
                header-class="nowrap"
                cell-class="nowrap"
                centered
                width="96"
                v-slot="props">
                <span v-if="props.row.proposed_at !== null">{{ $dates.utc(props.row.proposed_at).local().format('MMM D') }}</span>
                <span v-else class="has-text-grey is-italic">--</span>
              </b-table-column>
              <!-- Acknowledged -->
              <b-table-column
                label="Acknowledged"
                header-class="nowrap"
                cell-class="nowrap"
                centered
                width="128"
                v-slot="props">
                <span v-if="props.row.acknowledged_at !== null">{{ $dates.utc(props.row.acknowledged_at).local().format('MMM D') }}</span>
                <span v-else class="has-text-grey is-italic">--</span>
              </b-table-column>
              <!-- Status -->
              <b-table-column
                label="Status"
                cell-class="nowrap"
                width="128"
                centered
                numeric
                v-slot="props">
                <StatusPresenter :proposal="props.row" />
              </b-table-column>
              <!-- Acknowledged -->
              <!-- <b-table-column
                label="Received"
                cell-class="nowrap"
                centered
                width="96"
                v-slot="props">
                <span v-if="props.row.acknowledged_at !== null">{{ $dates.utc(props.row.acknowledged_at).local().format('MMM D') }}</span>
                <span v-else class="has-text-grey is-italic">--</span>
              </b-table-column> -->
            </template>
            <template #empty>
              <div class="py-xxl has-text-grey has-text-centered">
                {{ (loading) ? "Loading data..." : 'No proposals found!' }}
              </div>
            </template>
          </b-table>
        </div>
        <b-pagination
          :total="total"
          :current.sync="currentPage"
          :rounded="true"
          :per-page="perPage"
          @change="paginate">
        </b-pagination>
      </div>
      <!-- Modal: Bulk Workflow -->
      <b-modal
        has-modal-card
        scroll="keep"
        :active.sync="showWorkflowActionsModal"
        :can-cancel="['x']"
        ><BulkWorkflowModal :proposals="selected" @reload="reload"></BulkWorkflowModal>
      </b-modal>
      <!-- Inspect Proposal -->
      <b-modal
        v-if="(inspectProposal !== null)"
        has-modal-card
        :active="(inspectProposal !== null)"
        :can-cancel="['escape', 'x']"
        @close="closeModals"
        ><ProposalModal :selectedProposal="inspectProposal" />
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import presets from '../presets'
import Filterable from '@/mixins/Filterable'
import ProposalModal from '@/components/Models/Proposal/Modals/ProposalModal'
import ProposalFilters from '../components/List/ProposalFilters'
import StatusPresenter from '@/components/Models/Proposal/Presenters/Status'
import TypePresenter from '@/components/Models/Proposal/Presenters/Type'
import BulkWorkflowModal from '../components/List/BulkWorkflowModal'

export default {
  components: {
    ProposalFilters,
    ProposalModal,
    StatusPresenter,
    TypePresenter,
    BulkWorkflowModal
  },
  data () {
    return {
      key: 'proposals',
      alwaysAttach: {
        short: 1,
        is_product_proposal: 0
        // mine: 1
      },
      presets: presets,
      // modals
      inspectProposal: null,
      showWorkflowActionsModal: false
    }
  },
  computed: {
    ...mapGetters(['context']),
    breadcrumbs () {
      return {
        current: 'List',
        history: [
          { label: 'Proposals', name: 'proposals.dashboard' }
        ]
      }
    },
    endpoint () {
      return this.context.route + '/proposals/'
    }
  },
  methods: {
    closeModals () {
      this.inspectProposal = null
    },
    reload () {
      this.selected = []
      this.load()
    }
  },
  mounted () {
    this.load()
  },
  mixins: [Filterable]
}
</script>
