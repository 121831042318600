<template>
<div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Send Proposal?</p>
    </header>
    <section class="modal-card-body">
      <!-- New Product Proposals required a compatibility check -->
      <div v-if="proposal.proposal_type === 'new_product'">
        <!-- Intro -->
        <div class="mb-lg is-size-7">
          <p>
            The results of your compatibility checks are below. To expedite review, please ensure
            all required attributes are present and that your supplied
            values match the criteria defined by {{ proposal.retailer.name }}.
          </p>
        </div>
        <!-- Results -->
        <div class="mb-lg">
          <div class="columns">
            <div class="column is-half">
              <div class="has-background-light rounded-lg pa-md has-text-centered">
                <p v-if="!loadingCompatibility" class="is-size-4 has-text-weight-bold">
                  <span v-if="vendorRuleset.passed === 0" class="has-text-danger">{{ vendorRuleset.passed }}</span>
                  <span v-else class="has-text-success">{{ vendorRuleset.passed }}</span>
                </p>
                <p v-else>Loading...</p>
                <p class="has-text-grey is-size-7">Items Passing Validation</p>
              </div>
            </div>
            <div class="column is-half">
              <div class="has-background-light rounded-lg pa-md has-text-centered">
                <p v-if="!loadingCompatibility" class="is-size-4 has-text-weight-bold">
                  <span v-if="vendorRuleset.failed === 0" class="has-text-success">{{ vendorRuleset.failed }}</span>
                  <span v-else class="has-text-danger">{{ vendorRuleset.failed }}</span>
                </p>
                <p v-else>Loading...</p>
                <p class="has-text-grey is-size-7">Items Failing Validation</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Other proposals do not require compatibility check -->
      <div v-else class="py-lg has-text-centered">
        <p>Are you sure you're ready to submit this proposal?</p>
      </div>
    </section>
    <footer class="modal-card-foot space-between" >
      <b-button @click="$parent.close()">Close</b-button>
      <b-button
        type="is-primary"
        :disabled="saving"
        :loading="saving"
        @click="save">Send Proposal {{ (isPassing) ? null : 'Anyways' }}</b-button>
    </footer>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {

  },
  computed: {
    ...mapGetters(['context']),
    vendorRuleset () {
      if (!this.proposal || this.rulesets.length === 0) return null
      const ruleset = this.rulesets.find(ruleset => {
        return ruleset.id === this.proposal.department.brand_ruleset.id
      })
      return (typeof ruleset !== 'undefined') ? ruleset : null
    },
    isPassing () {
      if (this.proposal === null) return false
      // always pass cost updates
      if (this.proposal.proposal_type === 'cost_update') return true
      // check all others...
      if (this.vendorRuleset) {
        return this.vendorRuleset.failed === 0 && this.vendorRuleset.processing === 0
      }
      return false
    }
  },
  data () {
    return {
      loadingCompatibility: true,
      saving: false,
      rulesets: []
    }
  },
  methods: {
    save () {
      this.saving = true
      const endpoint = this.context.route + '/proposals/' + this.proposal.id + '/'
      const body = {
        status: 'proposed',
        return_reason: null
      }
      this.$http.patch(endpoint, body).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Proposal sent!' })
        this.$parent.close()
        this.$emit('proposal:proposed')
      }).finally(() => {
        this.saving = false
      })
    },
    loadCompatibility (silent = false) {
      if (!silent) this.loadingCompatibility = true
      const endpoint = this.context.route + '/proposals/' + this.proposal.id + '/rulesets-summary/'
      this.$http.get(endpoint).then(response => {
        this.rulesets = response.data
        this.loadingCompatibility = false
      })
    }
  },
  mounted () {
    if (['new_product', 'attribute_update'].includes(this.proposal.proposal_type)) {
      this.loadCompatibility()
    }
  },
  props: ['proposal']
}
</script>
