<template>
<div>
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="mb-md">
        <div class="columns is-vcentered">
          <div class="column is-one-third">
            <breadcrumbs :history="breadcrumbs.history" :current="breadcrumbs.current" />
          </div>
          <div class="column is-one-third">
            <h1 class="has-text-centered is-size-4">Merchandise</h1>
          </div>
        </div>
      </div>
      <div v-if="!loading" class="columns is-centered">
        <div class="column is-half">
          <!-- Connection Banner -->
          <div class="has-background-white pa-md rounded-md mb-xxs has-text-centered">
            <div>
              <img v-if="context.isSupplier()" :src="connection.retailer.logo_url" style="height:60px" height="60" />
              <img v-else :src="connection.brand.logo_url" width="180" height="60"  />
            </div>
          </div>
          <!-- Proposal Options -->
          <div v-if="showProposalSummary" class="mb-lg">
            <ProposalSummary :connection="connection" />
          </div>
          <div v-else-if="showImportRequestSummary" class="mb-lg">
            <ImportRequestSummary :connection="connection" />
          </div>
        </div>
      </div>
      <!-- Loading State -->
      <div v-else class="py-lg has-text-centered has-text-grey-light">
        Checking connections...
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import ImportRequestSummary from '../components/Connection/ImportRequestSummary'
import ProposalSummary from '../components/Connection/ProposalSummary'
export default {
  components: {
    ImportRequestSummary,
    ProposalSummary
  },
  computed: {
    ...mapGetters(['api', 'context']),
    breadcrumbs () {
      return {
        current: (!this.loadingConnection && this.connection) ? '#' + this.connection.id : 'Loading...',
        history: [
          { label: 'Catalog', name: this.context.isSupplier() ? 'catalog' : 'catalog.index' },
          { label: 'Merchandise', name: 'catalog.merchandise' }
        ]
      }
    },
    loading () {
      return Boolean(this.loadingConnection)
    },
    showProposalSummary () {
      return Boolean(this.connection && ('merchandise_via_proposals' in this.connection.options || 'merchandise_via_product_proposals' in this.connection.options))
    },
    showImportRequestSummary () {
      if (this.connection && ('merchandise_via_proposals' in this.connection.options || 'merchandise_via_product_proposals' in this.connection.options)) {
        return false
      }
      return true
    }
  },
  data () {
    return {
      loadingConnection: true,
      loadingProposalSummary: true,
      connection: null,
      proposals: null
    }
  },
  methods: {
    loadConnection () {
      this.loadingConnection = true
      const endpoint = this.context.route + '/connections/' + this.$route.params.connection + '/'
      this.$http.get(endpoint).then(response => {
        this.connection = response.data
        this.loadingConnection = false
        this.loadingProposalSummary = true
        this.loadProposalSummary()
      })
    },
    loadProposalSummary () {
      this.loadingProposalSummary = true
      const endpoint = this.context.route + '/proposals/summary/'
      const query = {

      }
      this.$http.get(endpoint, query).then(response => {
        this.proposals = response.results
      })
    }
  },
  mounted () {
    this.loadConnection()
  }
}
</script>
